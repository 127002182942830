const RoutesList = {
  login: '/',
  flightsSearch: '/flights/search',
  flightsResults: '/flights/results',
  flightBooking: '/flights/bookings/new',
  forgotPassword: '/forgot_password',
  userDashboard: '/agent/dashboard',
  bookingConfirmation: '/booking/confirmation',
  bookingDetails: '/booking/details',
  bookings: '/booking/list',
};

export default RoutesList;
