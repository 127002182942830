import React from 'react';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from 'react-error-boundary';
import { GlobalErrorPage } from './app/pages/GlobalErrorPage/GlobalErrorPage';
import { Router } from './app/routes/Routes';
import { SnackbarProvider } from 'notistack';
import './app/assets/stylesheets/bootstrap.min.css';
import './app/assets/stylesheets/animate.min.css';
import './app/assets/stylesheets/fontawesome.all.min.css';
import './app/assets/stylesheets/owl.carousel.min.css';
import './app/assets/stylesheets/navber.css';
import './app/assets/stylesheets/style.css';
import './app/assets/stylesheets/responsive.css';
const App = () => (
  <ErrorBoundary FallbackComponent={() => <GlobalErrorPage />}>
    <SnackbarProvider maxSnack={3}>
      <AppHead />
      <Router />
    </SnackbarProvider>
  </ErrorBoundary>
);

const AppHead = () => {
  return (
    <Helmet>
      <base href="/" />
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <title>Tajflights | It’s Time To Fly</title>
    </Helmet>
  );
};
export default App;
