import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TopBanner from '../../components/TopBanner/TopBanner';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import RoutesList from '../../constants/RoutesList';
import format from 'date-fns/format';
import { Button, Container } from '@mui/material';
import { BLACK, ERROR, showSnackbar } from '../../../utils';
import FlightCard from '../../components/FlightCard/FlightCard';
import Loading from '../../components/Loading/Loading';

const FlightResults = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const journeyDate = location?.state?.item?.journeyDate;
  const adultsCount = location?.state?.item?.adultsCount;
  const childCount = location?.state?.item?.childCount;
  const infantsCount = location?.state?.item?.infantsCount;
  const cameFrom = location?.state?.item?.cameFrom;
  const returnJourneyDate = location?.state?.item?.returnJourneyDate || null;

  const [onwardfare, setOnwardfare] = useState({});
  const [returnfare, setReturnfare] = useState({});

  if (cameFrom !== 'flightSearch') {
    return navigate(RoutesList.flightsSearch);
  }

  const displayErrorMessage = () => {
    if (returnJourneyDate === null && Object.keys(onwardfare).length === 0) {
      return 'Please select journey flight';
    } else if (
      returnJourneyDate !== null &&
      (Object.keys(onwardfare).length === 0 ||
        Object.keys(returnfare).length === 0)
    ) {
      return 'Please select both journey flights before proceeding';
    } else if (
      Object.keys(onwardfare).length !== 0 &&
      Object.keys(returnfare).length !== 0
    ) {
      if (
        format(new Date(onwardfare.departure_date), 'yyyy-MM-dd') >
        format(new Date(returnfare.departure_date), 'yyyy-MM-dd')
      ) {
        return 'Selected journey date cannot be greater then return journey date';
      }
    }
  };

  const canProceed = () => {
    if (returnJourneyDate === null && Object.keys(onwardfare).length !== 0) {
      return true;
    } else if (
      Object.keys(onwardfare).length !== 0 &&
      Object.keys(returnfare).length !== 0
    ) {
      if (
        format(new Date(onwardfare.departure_date), 'yyyy-MM-dd') <
        format(new Date(returnfare.departure_date), 'yyyy-MM-dd')
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <Header />
      <TopBanner pagName={'Flight Results'} />
      <section
        id="explore_area"
        className="section_padding"
        style={{ paddingTop: 50 }}
      >
        <center>
          <Container maxWidth="100%" style={{ paddingInline: 0 }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <FlightCard
                      journeyDate={journeyDate}
                      setFare={setOnwardfare}
                      selectedFare={onwardfare}
                      params={location?.state?.item}
                      roundTrip={false}
                    />

                    {returnJourneyDate !== null ? (
                      <FlightCard
                        journeyDate={returnJourneyDate}
                        setFare={setReturnfare}
                        selectedFare={returnfare}
                        outboundFare={onwardfare}
                        params={location?.state?.item}
                        roundTrip={true}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="top_form_search_button">
                  <Button
                    onClick={() => {
                      if (canProceed()) {
                        if (returnJourneyDate === null) {
                          navigate(RoutesList.flightBooking, {
                            state: {
                              item: {
                                fare: onwardfare,
                                adultsCount,
                                childCount,
                                infantsCount,
                                cameFrom: 'flightResults',
                              },
                            },
                          });
                        } else {
                          navigate(RoutesList.flightBooking, {
                            state: {
                              item: {
                                fare: onwardfare,
                                returnfare: returnfare,
                                adultsCount,
                                childCount,
                                infantsCount,
                                cameFrom: 'flightResults',
                              },
                            },
                          });
                        }
                      } else {
                        showSnackbar(displayErrorMessage(), ERROR);
                      }
                    }}
                    variant="contained"
                    className="btn btn_theme btn_md"
                    style={{
                      backgroundColor: canProceed() ? BLACK : 'grey',
                    }}
                  >
                    PROCEED
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </center>
      </section>
      <Footer />
    </>
  );
};
export default FlightResults;
