import React, { useState } from 'react';
import { sanitizeText } from '../../../utils';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const InputField = ({
  field,
  formFields,
  background = '#f3f6fd',
  setFormFields,
  error,
  helperText,
  placeholder,
  type,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="form-group">
      <TextField
        type={type === 'password' ? (showPassword ? 'text' : type) : type}
        fullWidth
        value={formFields[field]}
        // className="form-control bg_input"
        className="form-control"
        onChange={(e) => {
          setFormFields((formFields) => ({
            ...formFields,
            [field]:
              type === 'password'
                ? sanitizeText(e.target.value)
                : e.target.value,
          }));
        }}
        error={error}
        id="standard-error-helper-text"
        helperText={helperText}
        required
        margin="dense"
        style={{
          backgroundColor: background,
        }}
        sx={{
          '& input': {
            background: background,
            color: '#212529',
          },
          '& label.Mui-focused': {
            color: 'white',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#8b3eea',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#8b3eea',
            },
          },
        }}
        placeholder={placeholder}
        InputProps={{
          // <-- This is where the toggle button is added.
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {type === 'password' ? (
                  showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )
                ) : null}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default InputField;
