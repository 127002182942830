// Host
export const HOST = "https://backend.tajflights.com";
// export const HOST =
//   'https://57f4-2401-4900-1c74-1172-8d75-c97c-5c7a-82ca.ngrok-free.app';
const BASE = HOST + "/api";

// App Config
export const APP_CONFIG = BASE + "/app/configuration";

// Auth
export const LOGIN = BASE + "/login";
export const FORGOT_PASSWORD = BASE + "/forgot_password";
export const VERIFY_OTP = FORGOT_PASSWORD + "/otp";
export const RESET_PASSWORD = BASE + "/reset_password";

// Agent Details
export const ME = BASE + "/agent/get";
export const UPDATE_DETAILS = BASE + "/profile";
export const UPDATE_MARKUP = BASE + "/agencies/update-profile";
export const CHANGE_PASSWORD = BASE + "/change-password";

// Search
export const SEARCH = BASE + "/search";

// Flight
export const FLIGHTS = BASE + "/flights";
export const AVAILABLE_DATES = FLIGHTS + "/available_dates";
export const AIRPORTS = BASE + "/airports";
export const CARRIERS = BASE + "/carriers";

// Bookings
export const BOOKINGS = BASE + "/bookings";

// Usertypes
export const MEMBER = "Member";
export const VENDOR = "Vendor";
export const ADMINISTRATOR = "Administrator";
export const USER = "User";

export const saveFcmToken = (fcm) => {
  localStorage.setItem("fcmToken", fcm);
};

export const getToken = () => {
  const token = localStorage.getItem("Token");
  return token;
};

export const getUserType = () => {
  const user_type = localStorage.getItem("userType");
  return user_type;
};

export const GST_REGEX =
  /^\d{2}[A-Za-z]{5}\d{4}[A-Za-z]{1}[A-Za-z\d]{1}[Zz]{1}[A-Za-z\d]{1}$/;

export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const getHeadersObject = () => {
  const token = getToken();
  return {
    Authorization: `${token}`,
    "Content-Type": "application/json",
    // 'ngrok-skip-browser-warning': '69420',
  };
};

const getHeadersFileObject = () => {
  const token = getToken();
  return {
    Authorization: `${token}`,
    "Content-Type": "multipart/form-data",
  };
};

export const getImageUrl = (url) => {
  const completeUrl = url?.indexOf("http") > -1 ? url : HOST + url;
  return completeUrl;
};

export function splitArrayIntoChunksOfLen(arr, len) {
  var chunks = [],
    i = 0,
    n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
}

// API Wrappers
export const returnOrThrow = async (resJSON) => {
  let result;
  const status = resJSON.status;
  if (status === 404 || status >= 500) {
    throw `Something went wrong!`;
  } else if (status === 401) {
    throw "sessionTimeout";
  }
  try {
    result = await resJSON.json();
  } catch (err) {
    result = { error: "Something went wrong" };
  }
  if (status !== 200) {
    throw result.error;
  }
  return result;
};

export const putWrapper = async (url, body, fileUpload = false) => {
  const headers = fileUpload
    ? await getHeadersFileObject()
    : await getHeadersObject();
  const resJSON = await fetch(url, {
    method: "PUT",
    body: fileUpload ? body : JSON.stringify(body),
    headers,
  });
  return resJSON;
};

export const postWrapper = async (url, body, fileUpload = false) => {
  const headers = fileUpload
    ? await getHeadersFileObject()
    : await getHeadersObject();
  const resJSON = await fetch(url, {
    method: "POST",
    body: fileUpload ? body : JSON.stringify(body),
    headers,
  });
  return resJSON;
};

export const patchWrapper = async (url, body, fileUpload = false) => {
  const headers = fileUpload
    ? await getHeadersFileObject()
    : await getHeadersObject();
  const resJSON = await fetch(url, {
    method: "PATCH",
    body: fileUpload ? body : JSON.stringify(body),
    headers,
  });
  return resJSON;
};

export const getWrapper = async (url, body = {}) => {
  const headers = await getHeadersObject();
  const resJSON = await fetch(url, {
    method: "GET",
    headers,
  });
  return resJSON;
};

export const deleteWrapper = async (url, body, fileUpload = false) => {
  const headers = fileUpload
    ? await getHeadersFileObject()
    : await getHeadersObject();
  const resJSON = await fetch(url, {
    method: "DELETE",
    body: fileUpload ? body : JSON.stringify(body),
    headers,
  });
  return resJSON;
};

export const invoiceURL = (id) => {
  return HOST + `/bookings/${id}/generate-invoice.pdf`;
};

export const pnrURL = (id, passenger = "all") => {
  return (
    HOST + `/bookings/${id}/generate-pnr-status.pdf?passenger=${passenger}`
  );
};
