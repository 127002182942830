import { enqueueSnackbar } from 'notistack';

export const SUCCESS = 'success';
export const INFO = 'info';
export const ERROR = 'error';

export const ECONOMY = 'Economy';
export const BUSINESS = 'Business';
export const FIRSTCLASS = 'First Class';

export const DASHBOARD = 'dashboard';
export const PROFILE = 'profile';
export const NOTIFICATION = 'notification';
export const MARKUPS = 'markups';

export const BLACK = '#3e3e3e';
export const RED = 'red';
export const GREEN = 'green';
export const ORANGE = 'orange';
export const LIGHT_BLUE = '#f3f6fd';
export const LIGHT_RED = '#C83C31';
export const WHITE = '#FFF';
export const LIGHT_YELLOW = '#F2C27F';

export const WEIGHT_30 = require('../src/app/assets/img/30_1.png');
export const WEIGHT_23_1 = require('../src/app/assets/img/23_1.png');
export const WEIGHT_23_2 = require('../src/app/assets/img/23_2.png');

export const SESSION_EXPIRED = 'Session Expired. Login to continue';
export const SOMETHING_WENT_WRONG = 'Something went wrong';

export const DELETED = 'Deleted';
export const CONFIRMED = 'Confirmed';
export const CANCELLED_BY_ADMIN = 'Cancelled by Admin';

export const showSnackbar = (message, type) => {
  enqueueSnackbar(message, {
    variant: `${type}`,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  });
};

export const titleize = (sentence) => {
  const words = sentence.replace('_', ' ').split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(' ');
};

export const priceRound = (value) => {
  return Math.round(value);
};

export const sanitizeText = (value) => {
  return value.replace(/\s+/g, '');
};

export const sanitizeNumber = (value) => {
  return value.replace(/^\d*\.?\d*$/, '');
};
