import React, { useState, useEffect } from 'react';
import TopBanner from '../../components/TopBanner/TopBanner';
import ProfileSidebar from '../../components/ProfileSidebar/ProfileSidebar';
import Dashboard from '../../components/Dashboard';
import MyProfile from '../../components/MyProfile';
import Notification from '../../components/Notification';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import RoutesList from '../../constants/RoutesList';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { getAgentData } from '../../api/agent';
import {
  DASHBOARD,
  ERROR,
  MARKUPS,
  NOTIFICATION,
  PROFILE,
  SOMETHING_WENT_WRONG,
  showSnackbar,
} from '../../../utils';
import Markups from '../../components/Markups';

const UserDashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(DASHBOARD);
  const [data, setData] = useState({});

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const results = await getAgentData();
      setData(results);
    } catch (err) {
      if (err === 'sessionTimeout') {
        localStorage.removeItem('Token');
        navigate(RoutesList.login);
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <TopBanner pagName={'Manage Profile'} />
      <section id="dashboard_main_arae" className="section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <ProfileSidebar
                data={data}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            {activeTab === DASHBOARD ? (
              <Dashboard data={data?.bookings} />
            ) : activeTab === PROFILE ? (
              <MyProfile data={data} />
            ) : activeTab === NOTIFICATION ? (
              <Notification data={data?.notifications} />
            ) : activeTab === MARKUPS ? (
              <Markups data={data} />
            ) : null}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default UserDashboard;
