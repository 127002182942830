import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Card from '@mui/material/Card';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { getAvailableDates } from '../../api/flight';
import RoutesList from '../../constants/RoutesList';
import {
  SESSION_EXPIRED,
  SOMETHING_WENT_WRONG,
  ERROR,
  showSnackbar,
} from '../../../utils';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { styles } from './styles';
import FlightsColumn from '../FlightsColumn/FlightsColumn';

const FlightCard = ({
  journeyDate,
  setFare,
  selectedFare,
  params,
  roundTrip,
  outboundFare = {},
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [availableDates, setAvailableDates] = useState({});
  const [page, setPage] = useState(1);

  const fetchAvailableDates = async (
    fromSelected,
    toSelected,
    seat_class,
    total,
    departureDate,
    roundTrip,
    fromSelectedCountry,
    toSelectedCountry
  ) => {
    setIsLoading(true);
    try {
      const results = await getAvailableDates(
        fromSelected,
        toSelected,
        seat_class,
        total,
        departureDate,
        roundTrip,
        fromSelectedCountry,
        toSelectedCountry
      );
      setAvailableDates(Object.fromEntries(Object.entries(results)));
    } catch (err) {
      if (err === 'sessionTimeout') {
        showSnackbar(SESSION_EXPIRED, ERROR);
        localStorage.removeItem('Token');
        navigate(RoutesList.login);
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params.toLocation !== undefined && params.fromLocation !== undefined) {
      fetchAvailableDates(
        roundTrip ? params.toLocation : params.fromLocation,
        roundTrip ? params.fromLocation : params.toLocation,
        params.seatClass,
        params.adultsCount + params.childCount + params.infantsCount,
        format(new Date(), 'yyyy-MM-dd'),
        roundTrip,
        roundTrip ? params?.toLocationCountry : params?.fromLocationCountry,
        roundTrip ? params?.fromLocationCountry : params?.toLocationCountry
      );
    }
  }, []);

  const availableDatesEntries = () => {
    let index = Object.keys(availableDates).indexOf(journeyDate);
    return Object.keys(availableDates).filter(
      (i) =>
        Object.keys(availableDates).indexOf(i) >= index ||
        Object.keys(availableDates).indexOf(i) >= index - 1 ||
        Object.keys(availableDates).indexOf(i) >= index - 2
    );
  };

  const calculation = useMemo(() => availableDatesEntries(), [availableDates]);

  const paginator = useCallback((items, current_page, per_page_items) => {
    let page = current_page || 1,
      per_page = per_page_items,
      offset = (page - 1) * per_page,
      paginatedItems = items?.slice(offset)?.slice(0, per_page_items),
      total_pages = Math.ceil(items?.length / per_page);
    // Array?.from(Array(5 - paginatedItems?.length))?.forEach((x, i) => {
    //   paginatedItems?.push(null);
    // });
    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: total_pages > page ? page + 1 : null,
      total: items?.length,
      total_pages: total_pages,
      data: paginatedItems,
    };
  }, []);

  return (
    <>
      <div className="flight_search_result_wrapper">
        <div className="flight_search_item_wrappper">
          <Card sx={styles.countryTxtBox}>
            <h4 style={styles.countryTxt}>
              {roundTrip
                ? params?.toLocationCountry
                : params?.fromLocationCountry}{' '}
              -{' '}
              {roundTrip
                ? params?.fromLocationCountry
                : params?.toLocationCountry}
            </h4>
          </Card>

          <div style={styles.flexBox}>
            <div style={{ flex: 0.25 }}>
              {page > 1 ? (
                <ArrowBackIosNewRoundedIcon
                  onClick={() => {
                    setPage(paginator(calculation, page - 1, 5)?.page);
                  }}
                  sx={styles.arrow}
                />
              ) : null}
            </div>
            {paginator(calculation, page, 5)?.data?.map((value, index) => {
              return (
                <FlightsColumn
                  date={value}
                  roundTrip={roundTrip}
                  setFare={setFare}
                  params={params}
                  selectedFare={selectedFare}
                  journeyDate={journeyDate}
                />
              );
            })}

            <div style={{ flex: 0.25 }}>
              {page < paginator(calculation, page, 5)?.total_pages ? (
                <ArrowForwardIosRoundedIcon
                  onClick={() => {
                    setPage(paginator(calculation, page + 1, 5)?.page);
                  }}
                  sx={styles.arrow}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlightCard;
