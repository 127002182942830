import {
  getWrapper,
  patchWrapper,
  returnOrThrow,
  ME,
  UPDATE_DETAILS,
  UPDATE_MARKUP,
  CHANGE_PASSWORD,
} from './util';

export const getAgentData = async () => {
  const resJSON = await getWrapper(ME);
  const result = await returnOrThrow(resJSON);
  return result;
};

export const updateDetails = async (data) => {
  const resJSON = await patchWrapper(UPDATE_DETAILS, data);
  const result = await returnOrThrow(resJSON);
  return result;
};

export const changePassword = async (data) => {
  const resJSON = await patchWrapper(CHANGE_PASSWORD, data);
  const result = await returnOrThrow(resJSON);
  return result;
};

export const updateMarkup = async (data) => {
  const resJSON = await patchWrapper(UPDATE_MARKUP, data);
  const result = await returnOrThrow(resJSON);
  return result;
};
