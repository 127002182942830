import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { format } from 'date-fns';

import React from 'react';
import { LIGHT_YELLOW } from '../../../utils';
import { styles } from './styles';

const DateCard = ({ date }) => {
  return (
    <Card sx={styles.dateCardBox}>
      <CardHeader
        sx={{
          p: 0.5,
          '&:last-child': { pb: 0 },
          borderRadius: 0,
        }}
        style={{
          textAlign: 'center',
          backgroundColor: LIGHT_YELLOW,
        }}
        titleTypographyProps={{
          color: 'black',
          fontSize: 16,
        }}
        subheaderTypographyProps={{
          fontWeight: 'bold',
          color: 'black',
        }}
        title={format(new Date(date), 'EEE')}
        subheader={format(new Date(date), 'dd MMM')}
      />
    </Card>
  );
};

export default DateCard;
