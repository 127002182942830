import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBanner from "../../components/TopBanner/TopBanner";
import Loading from "../../components/Loading/Loading";
import { login } from "../../api/auth";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InputField from "../../components/InputField/InputField";
import RoutesList from "../../constants/RoutesList";
import { Link } from "react-router-dom";
import { ERROR, SOMETHING_WENT_WRONG, showSnackbar } from "../../../utils";

const Login = () => {
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState({ username: "", password: "" });
  const [showErrorFields, setShowErrorFields] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isFormValid = () => {
    return formFields.username.length > 0 && formFields.password.length > 0;
  };

  const handlePostLogin = (result) => {
    if (result?.status == "ok") {
      localStorage.setItem("Token", result?.token);
      navigate(RoutesList.flightsSearch);
    } else if (result?.status == "error") {
      showSnackbar(result?.errors, "error");
    }
  };

  const loginUser = async (username, password) => {
    setIsLoading(true);
    if (!isFormValid()) {
      setIsLoading(false);
      return;
    }
    try {
      const result = await login(username, password);
      handlePostLogin(result);
    } catch (err) {
      showSnackbar(SOMETHING_WENT_WRONG, ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!localStorage.getItem("Token")) {
      navigate(RoutesList.flightsSearch);
    }
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <TopBanner pagName={"Login"} />
      <section id="common_author_area" className="section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="common_author_boxed">
                <div className="common_author_heading">
                  <h3>Login your account</h3>
                  <h2>Logged in to stay in touch</h2>
                </div>
                <div className="common_author_form">
                  <form
                    id="main_author_form"
                    onSubmit={() => {
                      if (
                        formFields.username.length > 0 &&
                        formFields.password.length > 0
                      ) {
                        loginUser(formFields.username, formFields.password);
                      } else {
                        setShowErrorFields(true);
                      }
                    }}
                  >
                    <InputField
                      field={"username"}
                      formFields={formFields}
                      setFormFields={setFormFields}
                      background="#FFF"
                      error={
                        showErrorFields
                          ? formFields.username.length === 0
                          : null
                      }
                      helperText={
                        showErrorFields && formFields.username.length === 0
                          ? "Username can not be blank"
                          : null
                      }
                      placeholder={"Enter user name"}
                    />

                    <InputField
                      field={"password"}
                      formFields={formFields}
                      setFormFields={setFormFields}
                      type="password"
                      background="#FFF"
                      error={
                        showErrorFields
                          ? formFields.password.length === 0
                          : null
                      }
                      helperText={
                        showErrorFields && formFields.password.length === 0
                          ? "Username can not be blank"
                          : null
                      }
                      placeholder={"Enter password"}
                    />

                    {/* <Link
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      to={RoutesList.forgotPassword}
                    >
                      Forgot password?
                    </Link> */}

                    <div className="common_form_submit">
                      <button className="btn btn_theme btn_md">Login</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Login;
