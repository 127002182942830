import React, { useEffect, useState } from "react";
import { appConfig } from "../../api/appConfig";
import { showSnackbar, SOMETHING_WENT_WRONG, ERROR } from "../../../utils";

import { Link, useNavigate } from "react-router-dom";
import RoutesList from "../../constants/RoutesList";
import { getToken } from "../../api/util";
import { BLACK } from "../../../utils";

const Header = () => {
  const [config, setConfig] = useState({ login_banner: "" });
  const [isLoading, setIsLoading] = useState(true);

  const getAppConfig = async () => {
    setIsLoading(true);
    try {
      const result = await appConfig();
      setConfig(result);
    } catch (err) {
      showSnackbar(SOMETHING_WENT_WRONG, ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAppConfig();
  }, []);

  const navigate = useNavigate();

  const isAuthenticated = !!localStorage.getItem("Token");

  const logout = () => {
    localStorage.removeItem("Token");
    navigate(RoutesList.login);
  };

  const styles = {
    loginBtn: {
      backgroundColor: BLACK,
      color: "white",
      borderRadius: 20,
      fontWeight: "bold",
      paddingInline: 35,
      fontSize: 20,
    },
  };

  return (
    <header className="main_header_arae">
      <div
        className="topbar-area"
        style={isAuthenticated ? { backgroundColor: "white" } : {}}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <ul className="topbar-list">
                <li>
                  {isLoading ? (
                    <p
                      style={
                        isAuthenticated
                          ? { color: "#3e3e3e", fontSize: 14 }
                          : { fontSize: 14, color: "white" }
                      }
                    >
                      Loading...
                    </p>
                  ) : (
                    <a
                      href={`tel:${config.contact_number_1}`}
                      style={isAuthenticated ? { color: "#3e3e3e" } : {}}
                    >
                      <span>{config.contact_number_1}</span>
                    </a>
                  )}
                </li>

                <li>
                  {isLoading ? (
                    <p
                      style={
                        isAuthenticated
                          ? { color: "#3e3e3e", fontSize: 14 }
                          : { fontSize: 14, color: "white" }
                      }
                    >
                      Loading...
                    </p>
                  ) : (
                    <a
                      href={`mailto:${config.email}`}
                      style={isAuthenticated ? { color: "#3e3e3e" } : {}}
                    >
                      <span>{config.email}</span>
                    </a>
                  )}
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6">
              <ul className="topbar-others-options">
                {getToken() != undefined ? (
                  <li>
                    <button
                      onClick={logout}
                      className="nav-link"
                      style={styles.loginBtn}
                    >
                      Logout
                    </button>
                  </li>
                ) : (
                  <li>
                    <Link
                      to={RoutesList.login}
                      className="nav-link"
                      style={styles.loginBtn}
                    >
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-area">
        <div className="main-responsive-nav">
          <div className="container">
            <div className="main-responsive-menu">
              <div className="logo">
                <a href="index.html">
                  <img
                    src={require("../../assets/img/travel-portal-logo.png")}
                    style={{ height: 50, width: 200, objectFit: "contain" }}
                    alt="logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {isAuthenticated ? (
          <div className="main-navbar">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light">
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link
                        to={RoutesList.flightsSearch}
                        className={`nav-link ${
                          window.location.pathname === RoutesList.flightsSearch
                            ? "active"
                            : ""
                        }`}
                      >
                        Flight Search <i className="fas fa-angle-down"></i>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={RoutesList.bookings}
                        className={`nav-link ${
                          window.location.pathname === RoutesList.bookings
                            ? "active"
                            : ""
                        }`}
                      >
                        Manage Booking <i className="fas fa-angle-down"></i>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={RoutesList.userDashboard}
                        className={`nav-link ${
                          window.location.pathname === RoutesList.userDashboard
                            ? "active"
                            : ""
                        }`}
                      >
                        Manage Profile <i className="fas fa-angle-down"></i>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link to={RoutesList.userDashboard} className="nav-link">
                        Support <i className="fas fa-angle-down"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        ) : null}
        <div className="others-option-for-responsive">
          <div className="container">
            <div className="dot-menu">
              <div className="inner">
                <div className="circle circle-one"></div>
                <div className="circle circle-two"></div>
                <div className="circle circle-three"></div>
              </div>
            </div>
            <div className="container">
              <div className="option-inner">
                <div className="others-options d-flex align-items-center">
                  <div className="option-item">
                    <a href="#" className="search-box">
                      <i className="fas fa-search"></i>
                    </a>
                  </div>
                  <div className="option-item">
                    <a href="contact.html" className="btn  btn_navber">
                      Get free quote
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
