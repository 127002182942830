import {
  LOGIN,
  returnOrThrow,
  postWrapper,
  FORGOT_PASSWORD,
  VERIFY_OTP,
  RESET_PASSWORD,
} from './util';

export const login = async (username, password) => {
  const resJSON = await fetch(
    `${LOGIN}?username=${username}&password=${password}`,
    {
      method: 'POST',
    }
  );
  const result = await returnOrThrow(resJSON);
  return result;
};

export const forgotPassword = async (data) => {
  const resJSON = await postWrapper(FORGOT_PASSWORD, data);
  const result = await returnOrThrow(resJSON);
  return result;
};

export const verifyOtp = async (data) => {
  const resJSON = await postWrapper(VERIFY_OTP, data);
  const result = await returnOrThrow(resJSON);
  return result;
};

export const resetPassword = async (data) => {
  const resJSON = await postWrapper(RESET_PASSWORD, data);
  const result = await returnOrThrow(resJSON);
  return result;
};
