import React, { useEffect, useState } from "react";
import { appConfig } from "../../api/appConfig";
import { showSnackbar, SOMETHING_WENT_WRONG, ERROR } from "../../../utils";
import { getImageUrl } from "../../api/util";
import { Link } from "react-router-dom";
import RoutesList from "../../constants/RoutesList";
import { BLACK } from "../../../utils";

const TopBanner = ({ pagName, bannerImageName }) => {
  const [config, setConfig] = useState({ login_banner: "" });
  const [isLoading, setIsLoading] = useState(true);

  const getAppConfig = async () => {
    setIsLoading(true);
    try {
      const result = await appConfig();
      setConfig(result);
    } catch (err) {
      showSnackbar(SOMETHING_WENT_WRONG, ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAppConfig();
  }, []);

  const isAuthenticated = !!localStorage.getItem("Token");

  return (
    <section
      id="common_banner"
      style={
        !isAuthenticated
          ? {
              backgroundImage: "url(" + getImageUrl(config.login_banner) + ")",
              backgroundSize: "cover",
              backgroundColor: "#3e3e3e",
              backgroundRepeat: "no-repeat",
              height: 500,
              padding: "200px 0 130px",
            }
          : {
              backgroundColor: "#3e3e3e",
              padding: "30px 0 50px 0",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }
      }
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="common_bannner_text">
              <h2>{isAuthenticated ? "" : "Login"}</h2>
              {isAuthenticated ? (
                <ul>
                  <li></li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
