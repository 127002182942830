import {
  getWrapper,
  returnOrThrow,
  AVAILABLE_DATES,
  AIRPORTS,
  FLIGHTS,
  CARRIERS,
} from './util';

export const getAvailableDates = async (
  from_location,
  to_location,
  seat_class,
  seats_count,
  departure_at,
  roundTrip,
  fromLocationCountry,
  toLocationCountry
) => {
  const resJSON = await getWrapper(
    `${AVAILABLE_DATES}?from_location=${from_location}&to_location=${to_location}&seat_class=${seat_class}&seats_count=${seats_count}&departure_at=${departure_at}&roundTrip=${roundTrip}&to_location_country=${toLocationCountry}&from_location_country=${fromLocationCountry}`
  );
  const result = await returnOrThrow(resJSON);
  return result;
};

export const getAirports = async () => {
  const resJSON = await getWrapper(AIRPORTS);
  const result = await returnOrThrow(resJSON);
  return result;
};

export const getCarriers = async () => {
  const resJSON = await getWrapper(CARRIERS);
  const result = await returnOrThrow(resJSON);
  return result;
};

export const getFlightResults = async (data) => {
  const resJSON = await getWrapper(
    `${FLIGHTS}?from_location=${data.fromLocation}&to_location=${data.toLocation}&seat_class=${data.seatClass}&seats_count=${data.requriredSeats}&departure_at=${data.journeyDate}&to_location_country=${data?.toLocationCountry}&from_location_country=${data?.fromLocationCountry}`
  );
  const result = await returnOrThrow(resJSON);
  return result;
};
