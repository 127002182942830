import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RoutesList from '../../constants/RoutesList';
import { DASHBOARD, MARKUPS, NOTIFICATION, PROFILE } from '../../../utils';

const ProfileSidebar = ({ data, activeTab, setActiveTab }) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('Token');
    navigate(RoutesList.login);
  };

  return (
    <div className="dashboard_sidebar">
      <div className="dashboard_sidebar_user">
        {/* <img src="assets/img/common/dashboard-user.png" alt="img" /> */}
        <h3>{data?.name ?? '-'}</h3>
        <p>
          <a href="tel:+00-123-456-789">{data?.mobile_number ?? '-'}</a>
        </p>
        <p>
          <a href="mailto:sherlyn@domain.com">{data?.email ?? '-'}</a>
        </p>
      </div>
      <div className="dashboard_menu_area">
        <ul>
          <li>
            <a
              className={activeTab === DASHBOARD ? 'active' : ''}
              style={{
                fontWeight: activeTab === DASHBOARD ? 'bold' : '400',
              }}
              onClick={() => {
                setActiveTab(DASHBOARD);
              }}
            >
              <i className="fas fa-tachometer-alt"></i>Dashboard
            </a>
          </li>

          <li>
            <a
              className={activeTab === MARKUPS ? 'active' : ''}
              style={{
                fontWeight: activeTab === MARKUPS ? 'bold' : '400',
              }}
              onClick={() => {
                setActiveTab(MARKUPS);
              }}
            >
              <i className="fas fa-user-circle"></i>Markups
            </a>
          </li>

          <li>
            <a
              className={activeTab === PROFILE ? 'active' : ''}
              style={{
                fontWeight: activeTab === PROFILE ? 'bold' : '400',
              }}
              onClick={() => {
                setActiveTab(PROFILE);
              }}
            >
              <i className="fas fa-user-circle"></i>My profile
            </a>
          </li>

          <li>
            <a
              className={activeTab === NOTIFICATION ? 'active' : ''}
              style={{
                fontWeight: activeTab === NOTIFICATION ? 'bold' : '400',
              }}
              onClick={() => {
                setActiveTab(NOTIFICATION);
              }}
            >
              <i className="fas fa-bell"></i>Notifications
            </a>
          </li>
          <li>
            <a
              onClick={logout}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <i className="fas fa-sign-out-alt"></i>Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileSidebar;
