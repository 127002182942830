import React, { useState, useContext, useEffect } from 'react';
import Loading from '../../components/Loading/Loading';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import TopBanner from '../../components/TopBanner/TopBanner';
import { useNavigate, useLocation } from 'react-router-dom';
import PassengerForm from '../../components/PassengerFrom';
import RoutesList from '../../constants/RoutesList';
import { Button } from '@mui/material';
import {
  BLACK,
  ERROR,
  SESSION_EXPIRED,
  SOMETHING_WENT_WRONG,
  showSnackbar,
} from '../../../utils';
import { flashBooking } from '../../api/booking';
import { BookingContext } from '../../constants/Context';

const FlightBooking = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { bookingState, replaceData } = useContext(BookingContext);
  const flightFare = location?.state?.item?.fare;
  const flightFareReturn = location?.state?.item?.returnfare || null;
  const adultsCount = location?.state?.item?.adultsCount;
  const childCount = location?.state?.item?.childCount;
  const infantsCount = location?.state?.item?.infantsCount;
  const cameFrom = location?.state?.item?.cameFrom;

  const [passengers, setPassengers] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [showErrorFields, setShowErrorFields] = useState(false);

  const computeTotalPrice = (flightFare) => {
    let total =
      adultsCount * (flightFare?.adult_price || 0.0) +
      childCount * (flightFare?.child_price || 0.0) +
      infantsCount * (flightFare?.infant_price || 0.0);

    return total;
  };

  function randomString(anysize) {
    var charset = 'abcdefghijklmnopqrstuvwxyz';
    var res = '';
    while (anysize--) res += charset[(Math.random() * charset.length) | 0];
    return res;
  }

  const passengersDataInitialObject = () => {
    const obj = {};
    for (let i = 1; i <= adultsCount; i++) {
      obj[randomString(5)] = {
        pax_type: 'Adult',
        isValidated: false,
      };
    }
    for (let i = 1; i <= childCount; i++) {
      obj[randomString(5)] = {
        pax_type: 'Child',
        isValidated: false,
      };
    }
    for (let i = 1; i <= infantsCount; i++) {
      obj[randomString(5)] = {
        pax_type: 'Infant',
        isValidated: false,
      };
    }
    return obj;
  };

  const canProceed = () => {
    if (bookingState['passengers'] !== undefined) {
      return (
        Object.entries(bookingState?.passengers).filter(
          (passenger) => passenger[1].isValidated
        ).length ===
        adultsCount + childCount + infantsCount
      );
    }
  };

  const newBooking = async () => {
    setIsLoading(true);
    try {
      const obj = {
        passengers: bookingState.passengers,
        total:
          computeTotalPrice(flightFare) +
          (flightFareReturn === null
            ? 0
            : computeTotalPrice(flightFareReturn)) -
          (computeDiscount() ?? 0.0),
        sub_total:
          computeTotalPrice(flightFare) +
          (flightFareReturn === null ? 0 : computeTotalPrice(flightFareReturn)),
        discount: computeDiscount() ?? 0.0,
        fare_id: flightFare?.id,
        return_fare_id: flightFareReturn?.id,
        booking_type: flightFareReturn === null ? 'One Way' : 'Round Trip',
      };
      if (flightFareReturn === null) {
        delete obj?.return_fare_id;
      }
      const result = await flashBooking(obj);
      if (result?.status === 'ok') {
        navigate(RoutesList.bookingConfirmation, {
          state: {
            item: {
              id: result?.booking_id,
            },
          },
        });
      } else {
        showSnackbar(result.error);
      }
    } catch (err) {
      if (err === 'sessionTimeout') {
        showSnackbar(SESSION_EXPIRED, ERROR);
        localStorage.removeItem('Token');
        navigate(RoutesList.login);
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const computeDiscount = () => {
    if (flightFare.discount !== null) {
      const returnFareDiscount =
        flightFareReturn.discount != null
          ? parseFloat(flightFareReturn.discount) * (adultsCount + childCount)
          : 0.0;
      return parseFloat(flightFare?.discount) * (adultsCount + childCount) >=
        returnFareDiscount
        ? parseFloat(flightFare?.discount) * (adultsCount + childCount)
        : returnFareDiscount;
    } else {
      return flightFareReturn?.discount != null
        ? parseFloat(flightFareReturn.discount) * (adultsCount + childCount)
        : null;
    }
  };

  useEffect(() => {
    replaceData({ passengers: passengersDataInitialObject() });
  }, [passengers]);

  if (cameFrom !== 'flightResults') {
    return navigate(RoutesList.flightsSearch);
  }

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <TopBanner pagName={'Flight Booking'} />
      <section id="tour_booking_submission" className="section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="tou_booking_form_Wrapper">
                <div className="booking_tour_form">
                  <h3 className="heading_theme">Passenger information</h3>

                  {Object.keys(
                    bookingState.passengers === undefined
                      ? {}
                      : bookingState.passengers
                  ).map((passenger, i) => {
                    return (
                      <PassengerForm
                        passengerNumber={i}
                        passengerId={passenger}
                        bookingContext={BookingContext}
                        showErrorFields={showErrorFields}
                        arrivalDate={flightFare.arrival_date}
                      />
                    );
                  })}
                </div>

                <div className="booking_tour_form_submit">
                  {/* <div className="form-check write_spical_check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefaultf1"
                    />
                    <label
                      className="form-check-label"
                      for="flexCheckDefaultf1"
                    >
                      I read and accept all{' '}
                      <a href="terms-service.html">Terms and conditions</a>
                    </label>
                  </div> */}
                  <Button
                    onClick={() => {
                      if (canProceed()) {
                        newBooking();
                      } else {
                        setShowErrorFields(true);
                        showSnackbar(
                          'Please fill all required fields correctly',
                          ERROR
                        );
                      }
                    }}
                    variant="contained"
                    className="btn btn_theme btn_md"
                    style={{
                      backgroundColor: canProceed() ? BLACK : 'grey',
                    }}
                  >
                    Proceed
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tour_details_right_sidebar_wrapper">
                <div className="tour_detail_right_sidebar">
                  <div className="tour_details_right_boxed">
                    <div className="tour_details_right_box_heading">
                      <h3>Flights</h3>
                    </div>
                    <div className="flight_sidebar_right">
                      <div className="flight_search_left_sidebar">
                        <div className="flight_search_destination_sidebar">
                          <p>From</p>
                          <h3>
                            {
                              flightFare?.seat_class?.flight?.departure_airport
                                ?.address?.city
                            }
                          </h3>
                          <h6>
                            {
                              flightFare?.seat_class?.flight?.departure_airport
                                ?.code
                            }{' '}
                            -{' '}
                            {
                              flightFare?.seat_class?.flight?.departure_airport
                                ?.name
                            }
                          </h6>
                        </div>
                      </div>
                      <div className="flight_search_middel_sidebar">
                        <div className="flight_right_arrow_sidebar">
                          <img
                            src={require('../../assets/img/icon/right_arrow.png')}
                            alt="icon"
                          />
                          <h6>Non-stop</h6>
                        </div>
                        <div className="flight_search_destination_sidebar">
                          <p>To</p>
                          <h3>
                            {
                              flightFare?.seat_class?.flight?.arrival_airport
                                ?.address?.city
                            }
                          </h3>
                          <h6>
                            {
                              flightFare?.seat_class?.flight?.arrival_airport
                                ?.code
                            }{' '}
                            -{' '}
                            {
                              flightFare?.seat_class?.flight?.arrival_airport
                                ?.name
                            }
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="tour_package_details_bar_list">
                      <div className="flight_refund_policy">
                        <div className="TabPanelInner">
                          <div
                            className="tour_details_right_box_heading"
                            style={{ marginBottom: 20 }}
                          >
                            <h3>Baggage</h3>
                          </div>
                          <div className="flight_info_taable">
                            <h3>Cabin</h3>
                            <p>
                              <span>
                                {flightFare?.seat_class
                                  .cabin_baggage_allowance ?? '-'}{' '}
                                /
                              </span>{' '}
                              person
                            </p>

                            <h3>Checkin</h3>
                            <p>
                              <span>
                                {flightFare?.seat_class
                                  .check_in_baggage_allowance ?? '-'}{' '}
                                /
                              </span>{' '}
                              person
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tour_package_details_bar_price">
                      <h5>Price</h5>
                      <div className="tour_package_bar_price">
                        <h3>€{computeTotalPrice(flightFare)}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                {flightFareReturn !== null ? (
                  <div className="tour_detail_right_sidebar">
                    <div className="tour_details_right_boxed">
                      <div className="tour_details_right_box_heading">
                        <h3>Return Flights</h3>
                      </div>
                      <div className="flight_sidebar_right">
                        <div className="flight_search_left_sidebar">
                          <div className="flight_search_destination_sidebar">
                            <p>From</p>
                            <h3>
                              {
                                flightFareReturn?.seat_class?.flight
                                  ?.departure_airport?.address?.city
                              }
                            </h3>
                            <h6>
                              {
                                flightFareReturn?.seat_class?.flight
                                  ?.departure_airport?.code
                              }{' '}
                              -{' '}
                              {
                                flightFareReturn?.seat_class?.flight
                                  ?.departure_airport?.name
                              }
                            </h6>
                          </div>
                        </div>
                        <div className="flight_search_middel_sidebar">
                          <div className="flight_right_arrow_sidebar">
                            <img
                              src={require('../../assets/img/icon/right_arrow.png')}
                              alt="icon"
                            />
                            <h6>Non-stop</h6>
                          </div>
                          <div className="flight_search_destination_sidebar">
                            <p>To</p>
                            <h3>
                              {
                                flightFareReturn?.seat_class?.flight
                                  ?.arrival_airport?.address?.city
                              }
                            </h3>
                            <h6>
                              {
                                flightFareReturn?.seat_class?.flight
                                  ?.arrival_airport?.code
                              }{' '}
                              -{' '}
                              {
                                flightFareReturn?.seat_class?.flight
                                  ?.arrival_airport?.name
                              }
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="tour_package_details_bar_list">
                        <div className="flight_refund_policy">
                          <div className="TabPanelInner">
                            <div
                              className="tour_details_right_box_heading"
                              style={{ marginBottom: 20 }}
                            >
                              <h3>Baggage</h3>
                            </div>
                            <div className="flight_info_taable">
                              <h3>Cabin</h3>
                              <p>
                                <span>
                                  {flightFareReturn?.seat_class
                                    .cabin_baggage_allowance ?? '-'}{' '}
                                  /
                                </span>{' '}
                                person
                              </p>

                              <h3>Checkin</h3>
                              <p>
                                <span>
                                  {flightFareReturn?.seat_class
                                    .check_in_baggage_allowance ?? '-'}{' '}
                                  /
                                </span>{' '}
                                person
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tour_package_details_bar_price">
                        <h5>Price</h5>
                        <div className="tour_package_bar_price">
                          <h3>€{computeTotalPrice(flightFareReturn)}</h3>
                        </div>
                      </div>
                      {computeDiscount() ? (
                        <div className="tour_package_details_bar_price">
                          <h5>Discount</h5>
                          <div className="tour_package_bar_price">
                            <h3>€{computeDiscount() ?? '-'}</h3>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="tour_detail_right_sidebar">
                  <div className="tour_details_right_boxed">
                    <div className="tour_details_right_box_heading">
                      <h3>Travel date</h3>
                    </div>
                    <div className="edit_date_form">
                      <div className="form-group">
                        <label for="dates">Date</label>
                        <span
                          className="form-control"
                          style={{ alignItems: 'center', padding: 15 }}
                        >
                          {flightFare.departure_date}
                        </span>
                      </div>
                    </div>
                    {flightFareReturn !== null ? (
                      <div className="edit_date_form">
                        <div className="form-group">
                          <label for="dates">Return Date</label>
                          <span
                            className="form-control"
                            style={{ alignItems: 'center', padding: 15 }}
                          >
                            {flightFareReturn.departure_date}
                          </span>
                        </div>
                      </div>
                    ) : null}
                    <div className="tour_package_details_bar_list">
                      <h5>Tourist</h5>
                      <div className="select_person_item">
                        <div className="select_person_left">
                          <h6>Adult</h6>
                          <p>12y+</p>
                        </div>
                        <div className="select_person_right">
                          <h6>{adultsCount}</h6>
                        </div>
                      </div>

                      <div className="select_person_item">
                        <div className="select_person_left">
                          <h6>Children</h6>
                          <p>2 - 12 years</p>
                        </div>
                        <div className="select_person_right">
                          <h6>{childCount}</h6>
                        </div>
                      </div>
                      <div className="select_person_item">
                        <div className="select_person_left">
                          <h6>Infant</h6>
                          <p>Below 2 years</p>
                        </div>
                        <div className="select_person_right">
                          <h6>{infantsCount}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="edit_person">
                      {/* <p>Edit person</p> */}
                    </div>
                  </div>
                </div>

                <div className="tour_detail_right_sidebar">
                  <div className="tour_details_right_boxed">
                    <div className="tour_details_right_box_heading">
                      <h3>Booking amount</h3>
                    </div>
                    {flightFareReturn === null ? (
                      <div className="tour_booking_amount_area">
                        <ul>
                          <li>
                            Adult x 1{' '}
                            <span>
                              €{adultsCount * (flightFare?.adult_price || 0.0)}
                            </span>
                          </li>
                          {childCount != 0 ? (
                            <li>
                              Child x {childCount}{' '}
                              <span>
                                €{childCount * (flightFare?.child_price || 0.0)}
                              </span>
                            </li>
                          ) : null}
                          {infantsCount != 0 ? (
                            <li>
                              Infant x {infantsCount}
                              <span>
                                €
                                {infantsCount *
                                  (flightFare?.infant_price || 0.0)}
                              </span>
                            </li>
                          ) : null}
                        </ul>
                        <div className="total_subtotal_booking">
                          <h6>
                            Subtotal{' '}
                            <span>€{computeTotalPrice(flightFare)}</span>
                          </h6>
                        </div>

                        <div className="total_subtotal_booking">
                          <h6>
                            Total Amount
                            <span>€{computeTotalPrice(flightFare)}</span>
                          </h6>
                        </div>
                      </div>
                    ) : (
                      <div className="tour_booking_amount_area">
                        <ul>
                          <li>
                            Adult x {adultsCount}{' '}
                            <span>
                              €
                              {adultsCount * (flightFare?.adult_price || 0.0) +
                                adultsCount *
                                  (flightFareReturn?.adult_price || 0.0)}
                            </span>
                          </li>
                          {childCount != 0 ? (
                            <li>
                              Child x {childCount}{' '}
                              <span>
                                €
                                {childCount * (flightFare?.child_price || 0.0) +
                                  childCount *
                                    (flightFareReturn?.child_price || 0.0)}
                              </span>
                            </li>
                          ) : null}
                          {infantsCount != 0 ? (
                            <li>
                              Infant x {infantsCount}
                              <span>
                                €
                                {infantsCount *
                                  (flightFare?.infant_price || 0.0) +
                                  infantsCount *
                                    (flightFareReturn?.infant_price || 0.0)}
                              </span>
                            </li>
                          ) : null}
                        </ul>
                        <div className="total_subtotal_booking">
                          <h6>
                            Subtotal{' '}
                            <span>
                              €
                              {computeTotalPrice(flightFare) +
                                (flightFareReturn === null
                                  ? 0
                                  : computeTotalPrice(flightFareReturn))}
                            </span>
                          </h6>
                        </div>

                        {computeDiscount() ? (
                          <div className="total_subtotal_booking">
                            <h6>
                              Discount <span>- €{computeDiscount()}</span>
                            </h6>
                          </div>
                        ) : null}

                        <div className="total_subtotal_booking">
                          <h6>
                            Total Amount
                            <span>
                              €
                              {computeTotalPrice(flightFare) +
                                (flightFareReturn === null
                                  ? 0
                                  : computeTotalPrice(flightFareReturn)) -
                                (computeDiscount() ?? 0.0)}
                            </span>
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FlightBooking;
