import { useReducer, useMemo } from 'react';
import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';
import RoutesList from '../constants/RoutesList';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import UserDashboard from '../pages/UserDashboard/UserDashboard';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import FlightResults from '../pages/FlightResults/FlightResults';
import FlightBooking from '../pages/FlightBooking/FlightBooking';
import BookingDetails from '../pages/BookingDetails/BookingDetails';
import BookingList from '../pages/BookingList/BookingList';
import BookingConfirmation from '../pages/BookingConfirmation/BookingConfirmation';
import BookingReducer from '../constants/Reducer';
import { BookingContext } from '../constants/Context';

export const Router = () => {
  const [bookingState, dispatchBooking] = useReducer(BookingReducer, {});

  const bookingContext = useMemo(
    () => ({
      bookingState,
      modifyData: (data) => {
        dispatchBooking({ type: 'ADD', payload: data });
      },
      clearData: () => {
        dispatchBooking({ type: 'CLEAR' });
      },
      replaceData: (data) => {
        dispatchBooking({ type: 'REPLACE', payload: data });
      },
    }),
    [bookingState]
  );

  return (
    <BrowserRouter>
      <BookingContext.Provider value={bookingContext}>
        <Routes>
          <Route path={RoutesList.login} element={<Outlet />}>
            <Route index element={<Login />} />
            <Route
              path={RoutesList.flightsResults}
              element={<FlightResults />}
            />
            <Route
              path={RoutesList.flightBooking}
              element={<FlightBooking />}
            />
            <Route path={RoutesList.bookings} element={<BookingList />} />
            <Route
              path={RoutesList.bookingConfirmation}
              element={<BookingConfirmation />}
            />
            <Route
              path={RoutesList.bookingDetails}
              element={<BookingDetails />}
            />
            <Route path={RoutesList.flightsSearch} element={<Home />} />
            <Route
              path={RoutesList.userDashboard}
              element={<UserDashboard />}
            />
            <Route
              path={RoutesList.forgotPassword}
              element={<ForgotPassword />}
            />
          </Route>
        </Routes>
      </BookingContext.Provider>
    </BrowserRouter>
  );
};
