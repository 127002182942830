import React, { useEffect, useState } from "react";
import { appConfig } from "../../api/appConfig";
import { Link } from "react-router-dom";
import { showSnackbar, SOMETHING_WENT_WRONG, ERROR } from "../../../utils";
import RoutesList from "../../constants/RoutesList";
import Loading from "../Loading/Loading";

const Footer = () => {
  const [config, setConfig] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const isAuthenticated = !!localStorage.getItem("Token");

  const getAppConfig = async () => {
    setIsLoading(true);
    try {
      const result = await appConfig();
      setConfig(result);
    } catch (err) {
      showSnackbar(SOMETHING_WENT_WRONG, ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAppConfig();
  }, []);
  return (
    <>
      <footer
        id="footer_area"
        style={{ borderTop: "1px solid #dfdfdf94", backgroundColor: "#f3f6fd" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="footer_heading_area">
                <h5>Need any help?</h5>
              </div>
              <div className="footer_first_area">
                <div className="footer_inquery_area">
                  <h5>Call 24/7 for any help</h5>
                  <h3>
                    {isLoading ? (
                      "Loading..."
                    ) : (
                      <a href={`tel:${config.contact_number_1}`}>
                        {config.contact_number_1}
                      </a>
                    )}
                    <br />
                    {isLoading ? (
                      "Loading..."
                    ) : (
                      <a href={`tel:${config.contact_number_2}`}>
                        {config.contact_number_2}
                      </a>
                    )}
                  </h3>
                </div>
                <div className="footer_inquery_area">
                  <h5>Mail to our support team</h5>
                  <h3>
                    {isLoading ? (
                      "Loading..."
                    ) : (
                      <a href={`mailto:${config.email}`}>{config.email}</a>
                    )}
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12"></div>
            <div className="col-lg-5 col-md-6 col-sm-6 col-12">
              <div className="footer_heading_area">
                <h5>Why Tajflights?</h5>
              </div>
              <div className="footer_link_area">
                Tajflights.com has been committed to bringing travelers the best
                in value and quality travel Benifits. We are passionate about
                travel and providing corporate travelers high-touch services to
                facililate their travel business needs. The experience of
                booking your flight tickets through our desktop site can be done
                with complete ease and no hassles at all
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-12">
              {isAuthenticated ? (
                <>
                  <div className="footer_heading_area">
                    <h5>Links</h5>
                  </div>
                  <div className="footer_link_area">
                    <ul>
                      <li>
                        <Link to={RoutesList.flightsSearch}>Flight Search</Link>
                      </li>
                      <li>
                        <Link to={RoutesList.bookingList}>Manage Booking</Link>
                      </li>
                      <li>
                        <Link to={RoutesList.userDashboard}>
                          Manage Profile
                        </Link>
                      </li>

                      {/* <li>
                    <Link to={RoutesList.userDashboard}>Support</Link>
                  </li> */}
                    </ul>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </footer>
      <div
        className="copyright_area"
        style={{ borderTop: "1px solid #dfdfdf94" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="copyright_left" style={{ textAlign: "center" }}>
              <p>
                Copyright © {new Date().getFullYear()} tajflights. All Rights
                Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
