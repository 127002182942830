export default function BookingReducer(state, action) {
  switch (action.type) {
    case 'ADD':
      return {
        ...state,
        ...action.payload,
        passengers: { ...state.passengers, ...action.payload.passengers },
      };
    case 'CLEAR':
      return {};
    case 'REPLACE':
      return action.payload;
    default:
      return state;
  }
}
