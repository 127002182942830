import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';

export default function ErrorModal({
  openErrorModal,
  setOpenErrorModal,
  errors,
}) {
  const handleClose = () => {
    setOpenErrorModal(false);
  };

  return (
    <div>
      <Dialog
        open={openErrorModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            alignSelf: 'center',
            marginBlock: 20,
          }}
        >
          <CloseIcon sx={{ color: 'red', fontSize: 90 }} />
        </DialogTitle>
        <DialogContent>
          {errors?.map((item) => (
            <DialogContentText
              id="alert-dialog-description"
              style={{
                color: 'black',
                fontSize: 20,
                fontWeight: '500',
                marginInline: 100,
              }}
            >
              {item}
            </DialogContentText>
          ))}
        </DialogContent>
        <DialogActions
          style={{ alignSelf: 'center', marginBottom: 20, marginTop: 0 }}
          className="top_form_search_button"
        >
          <Button
            onClick={handleClose}
            className="btn btn_theme btn_md"
            style={{ backgroundColor: '#8b3eea', color: 'white' }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
