import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button } from '@mui/material';
import InputField from './InputField/InputField';
import {
  BLACK,
  ERROR,
  SOMETHING_WENT_WRONG,
  SUCCESS,
  showSnackbar,
} from '../../utils';
import { updateMarkup } from '../api/agent';
import Loading from './Loading/Loading';

const styles = {
  btn: {
    backgroundColor: BLACK,
    marginRight: 10,
    fontSize: 14,
  },
};

export default function Markups({ data }) {
  const [showErrorFields, setShowErrorFields] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formFields, setFormFields] = useState({
    markup: data?.agency?.markup_value ?? 0,
    markupType: data?.agency?.markup_type ?? 'Percentage',
  });

  const updateDetailsAction = async () => {
    setIsLoading(true);
    try {
      let obj = {
        markup_type: formFields.markupType,
        markup_value: formFields.markup,
        name: data?.name,
        mobile_number: data?.mobile_number,
      };
      const results = await updateMarkup(obj);
      if (results?.status === 'ok') {
        showSnackbar('Markup updated successfully', SUCCESS);
      }
    } catch (err) {
      if (err == 'sessionTimeout') {
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const validationError = () => {
    if (formFields?.markup.length === 0) {
      return 'Please enter markup value';
    } else if (formFields.markupType === 'Percentage') {
      if (
        parseInt(formFields.markup) > 100 ||
        parseInt(formFields.markup) < 0
      ) {
        return 'Markup value should be between 0 - 100';
      }
    } else if (formFields.markupType === 'Fixed') {
      if (parseInt(formFields.markup) < 0) {
        return 'Markup value should be greater than or equal to 0';
      }
    }
  };

  const canProceed = () => {
    if (formFields.markupType === 'Percentage') {
      return formFields.markup <= 100 && formFields.markup >= 0;
    } else if (formFields.markupType === 'Fixed') {
      return formFields.markup >= 0;
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className="col-lg-8">
      <div className="dashboard_common_table">
        <h3>Manage Flight Markup</h3>
        <div className="profile_update_form">
          <form action="!#" id="profile_form_area">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      onClick={() => {
                        setFormFields((formFields) => ({
                          ...formFields,
                          markupType: 'Fixed',
                        }));
                      }}
                      checked={formFields?.markupType === 'Fixed'}
                      value="Fixed"
                      control={
                        <Radio
                          sx={{
                            '&.Mui-checked': {
                              color: BLACK,
                            },
                          }}
                        />
                      }
                      label="Fixed"
                    />
                    <FormControlLabel
                      onClick={() => {
                        setFormFields((formFields) => ({
                          ...formFields,
                          markupType: 'Percentage',
                        }));
                      }}
                      checked={formFields?.markupType === 'Percentage'}
                      value="Percentage"
                      control={
                        <Radio
                          sx={{
                            '&.Mui-checked': {
                              color: BLACK,
                            },
                          }}
                        />
                      }
                      label="Percentage"
                    />
                  </RadioGroup>
                  <InputField
                    type={'number'}
                    field={'markup'}
                    formFields={formFields}
                    setFormFields={setFormFields}
                    background="#fff"
                    error={
                      showErrorFields ? formFields?.markup?.length === 0 : null
                    }
                    helperText={
                      showErrorFields && formFields?.markup?.length === 0
                        ? 'Markup can not be blank'
                        : formFields?.markupType === 'Percentage'
                        ? formFields?.markup >= 0 && formFields?.markup <= 100
                          ? 'Markup should be b/w 0 - 100'
                          : formFields?.markupType === 'Fixed'
                          ? formFields?.markup >= 0
                            ? 'Markup value should be greater than or equal to 0'
                            : null
                          : null
                        : null
                    }
                    placeholder={'Enter Markup Here!'}
                  />
                </div>
                <Button
                  onClick={() => {
                    if (canProceed()) {
                      updateDetailsAction();
                    } else {
                      showSnackbar(validationError(), ERROR);
                    }
                  }}
                  variant="contained"
                  className="btn btn_theme btn_md"
                  style={styles.btn}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
