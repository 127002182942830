import { returnOrThrow, BOOKINGS, postWrapper, getWrapper } from './util';

export const flashBooking = async (data) => {
  const resJSON = await postWrapper(BOOKINGS, data);
  const result = await returnOrThrow(resJSON);
  return result;
};

export const getBookings = async (data) => {
  const resJSON = await getWrapper(
    `${BOOKINGS}?toAirport=${data.toAirport}&fromAirport=${data.fromAirport}&airline=${data?.airline}&departureFrom=${data?.departureFrom}&departureTo=${data?.departureTo}&status=${data?.status}&bookingFrom=${data?.bookingFrom}&bookingTo=${data?.bookingTo}`
  );
  const result = await returnOrThrow(resJSON);
  return result;
};

export const bookingDetail = async (id) => {
  const resJSON = await getWrapper(`${BOOKINGS}/${id}`);
  const result = await returnOrThrow(resJSON);
  return result;
};
