import React, { useState, useEffect } from 'react';
import TopBanner from '../../components/TopBanner/TopBanner';
import Loading from '../../components/Loading/Loading';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import {
  CANCELLED_BY_ADMIN,
  DELETED,
  ERROR,
  RED,
  SESSION_EXPIRED,
  SOMETHING_WENT_WRONG,
  priceRound,
  showSnackbar,
} from '../../../utils';
import { getImageUrl, invoiceURL, pnrURL } from '../../api/util';
import { useNavigate, Link } from 'react-router-dom';
import { bookingDetail } from '../../api/booking';
import RoutesList from '../../constants/RoutesList';
import AirlinesRoundedIcon from '@mui/icons-material/AirlinesRounded';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import AirlineSeatReclineNormalSharpIcon from '@mui/icons-material/AirlineSeatReclineNormalSharp';

const BookingDetails = () => {
  const navigate = useNavigate();

  const bookingId = new URL(document.location).searchParams.get('booking_id');

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  const countPassengerOnType = (paxType) => {
    return data?.passengers?.filter((pax) => pax?.pax_type === paxType)?.length;
  };

  const pricePassengerOnType = (paxType) => {
    return data?.passengers?.find((pax) => pax?.pax_type === paxType)
      ?.ticket_price;
  };

  const fetchBookingDetails = async () => {
    setIsLoading(true);
    try {
      const result = await bookingDetail(bookingId);
      setData(result);
    } catch (err) {
      if (err === 'sessionTimeout') {
        showSnackbar(SESSION_EXPIRED, ERROR);
        localStorage.removeItem('Token');
        navigate(RoutesList.login);
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBookingDetails();
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <TopBanner pagName={'Booking Details'} />
      <section id="tour_booking_submission" className="section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="tou_booking_form_Wrapper">
                <div className="booking_tour_form">
                  <h3 className="heading_theme">Flight Information</h3>
                  <div
                    className="flight_search_items tour_booking_form_box"
                    style={{
                      justifyContent: 'center',
                      borderWidth: 0,
                    }}
                  >
                    <div className="multi_city_flight_lists">
                      <div className="flight_multis_area_wrapper">
                        <div>
                          <div
                            className="booking_success_img"
                            style={{ width: 50, height: 50 }}
                          >
                            {data?.carrier?.logo != null ? (
                              <img
                                src={getImageUrl(data?.carrier?.logo)}
                                alt="img"
                              />
                            ) : (
                              <AirlinesRoundedIcon
                                fontSize="large"
                                color="info"
                                style={{ alignSelf: 'center' }}
                              />
                            )}
                          </div>
                          <h3 style={{ fontWeight: '500', fontSize: 14 }}>
                            {data?.carrier?.name}
                          </h3>
                          <h3 style={{ fontWeight: '500' }}>
                            {data?.onward_trip_flight_number}
                          </h3>
                        </div>
                        <div className="flight_search_left">
                          <div className="flight_logo">
                            <img
                              src={require('../../assets/img/icon/plan-md.png')}
                              width={75}
                              height={75}
                              style={{ resizeMode: 'cover' }}
                            />
                          </div>
                          <div className="flight_search_destination">
                            <p>From</p>
                            <h3>
                              {data?.onward_departure_airport?.address?.city}
                            </h3>
                            <h6 style={{ width: 205 }}>
                              {data?.onward_departure_airport?.code}
                              {' - '}
                              {data?.onward_departure_airport?.name}
                            </h6>
                            <h6 style={{ width: 205 }}>
                              {data?.onward_departure_date}
                              {' - '}
                              {data?.onward_departure_time}
                            </h6>
                          </div>
                        </div>
                        <div className="flight_search_middel">
                          <div className="flight_right_arrow">
                            <img
                              src={require('../../assets/img/icon/right_arrow.png')}
                              alt="icon"
                            />
                            <h6>Non-stop</h6>
                          </div>
                          <div className="flight_search_destination">
                            <p>To</p>
                            <h3>
                              {data?.onward_arrival_airport?.address?.city}{' '}
                            </h3>
                            <h6 style={{ width: 205 }}>
                              {data?.onward_arrival_airport?.code}
                              {' - '}
                              {data?.onward_arrival_airport?.name}
                            </h6>
                            <h6 style={{ width: 205 }}>
                              {data?.onward_arrival_date}
                              {' - '}
                              {data?.onward_arrival_time}
                            </h6>
                          </div>
                        </div>
                        <div
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <AirlineSeatReclineNormalSharpIcon
                            fontSize="large"
                            color="info"
                            style={{ alignSelf: 'center' }}
                          />
                          <h3
                            style={{
                              fontWeight: '500',
                            }}
                          >
                            {data?.onward_trip_seat_class}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  {data?.booking_type === 'Round Trip' ? (
                    <div
                      className="flight_search_items tour_booking_form_box"
                      style={{
                        justifyContent: 'center',
                        borderWidth: 0,
                        marginTop: 10,
                      }}
                    >
                      <div className="multi_city_flight_lists">
                        <div className="flight_multis_area_wrapper">
                          <div>
                            <div
                              className="booking_success_img"
                              style={{ width: 50, height: 50 }}
                            >
                              {data?.carrier?.logo != null ? (
                                <img
                                  src={getImageUrl(data?.carrier?.logo)}
                                  alt="img"
                                />
                              ) : (
                                <AirlinesRoundedIcon
                                  fontSize="large"
                                  color="info"
                                  style={{ alignSelf: 'center' }}
                                />
                              )}
                            </div>
                            <h3 style={{ fontWeight: '500', fontSize: 14 }}>
                              {data?.carrier?.name}
                            </h3>
                            <h3 style={{ fontWeight: '500' }}>
                              {data?.return_trip_flight_number}
                            </h3>
                          </div>
                          <div className="flight_search_left">
                            <div className="flight_logo">
                              <img
                                src={require('../../assets/img/icon/plan-md.png')}
                                width={75}
                                height={75}
                                style={{ resizeMode: 'cover' }}
                              />
                            </div>
                            <div className="flight_search_destination">
                              <p>From</p>
                              <h3>
                                {data?.return_departure_airport?.address?.city}
                              </h3>
                              <h6 style={{ width: 205 }}>
                                {data?.return_departure_airport?.code}
                                {' - '}
                                {data?.return_departure_airport?.name}
                              </h6>
                              <h6 style={{ width: 205 }}>
                                {data?.return_departure_date}
                                {' - '}
                                {data?.return_departure_time}
                              </h6>
                            </div>
                          </div>
                          <div className="flight_search_middel">
                            <div className="flight_right_arrow">
                              <img
                                src={require('../../assets/img/icon/right_arrow.png')}
                                alt="icon"
                              />
                              <h6>Non-stop</h6>
                            </div>
                            <div className="flight_search_destination">
                              <p>To</p>
                              <h3>
                                {data?.return_arrival_airport?.address?.city}{' '}
                              </h3>
                              <h6 style={{ width: 205 }}>
                                {data?.return_arrival_airport?.code}
                                {' - '}
                                {data?.return_arrival_airport?.name}
                              </h6>
                              <h6 style={{ width: 205 }}>
                                {data?.return_arrival_date}
                                {' - '}
                                {data?.return_arrival_time}
                              </h6>
                            </div>
                          </div>
                          <div
                            style={{
                              alignItems: 'center',
                            }}
                          >
                            <AirlineSeatReclineNormalSharpIcon
                              fontSize="large"
                              color="info"
                              style={{ alignSelf: 'center' }}
                            />
                            <h3
                              style={{
                                fontWeight: '500',
                              }}
                            >
                              {data?.return_trip_seat_class}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <h3
                    className="heading_theme"
                    style={{ paddingBottom: 0, marginBottom: 0, marginTop: 40 }}
                  >
                    Passenger Information
                  </h3>

                  {data?.passengers?.map((passenger) => {
                    return (
                      <div
                        className="tour_booking_form_box"
                        style={{ marginTop: 20 }}
                      >
                        <div className="your_info_arae">
                          <ul>
                            <li
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Link
                                to={pnrURL(
                                  data?.reference_number,
                                  passenger?.pnr
                                )}
                                target="_blank"
                              >
                                <LocalPrintshopRoundedIcon
                                  className="last_name"
                                  fontSize="large"
                                  color="inherit"
                                  style={{ alignSelf: 'flex-end' }}
                                />
                              </Link>
                            </li>

                            <li>
                              <span className="name_first">Title:</span>
                              <span className="last_name">
                                {passenger?.title ?? '-'}
                              </span>
                            </li>

                            <li>
                              <span className="name_first">First Name:</span>
                              <span className="last_name">
                                {passenger?.first_name ?? '-'}
                              </span>
                            </li>

                            <li>
                              <span className="name_first">Last Name:</span>
                              <span className="last_name">
                                {passenger?.last_name ?? '-'}
                              </span>
                            </li>

                            <li>
                              <span className="name_first">PNR:</span>
                              <span className="last_name">
                                {passenger?.pnr ?? '-'}
                              </span>
                            </li>

                            <li>
                              <span className="name_first">PAX TYPE:</span>
                              <span className="last_name">
                                {passenger?.pax_type ?? '-'}
                              </span>
                            </li>

                            <li>
                              <span className="name_first">Gender:</span>
                              <span className="last_name">
                                {passenger?.gender ?? '-'}
                              </span>
                            </li>

                            <li>
                              <span className="name_first">Nationality:</span>
                              <span className="last_name">
                                {passenger?.nationality ?? '-'}
                              </span>
                            </li>

                            <li>
                              <span className="name_first">Email address:</span>{' '}
                              <span className="last_name">
                                {passenger?.email ?? '-'}
                              </span>
                            </li>
                            <li>
                              <span className="name_first">DOB:</span>{' '}
                              <span className="last_name">
                                {passenger?.dob ?? '-'}
                              </span>
                            </li>

                            <li>
                              <span className="name_first">
                                PASSPORT NUMBER:
                              </span>
                              <span className="last_name">
                                {passenger?.passport_number ?? '-'}
                              </span>
                            </li>

                            <li>
                              <span className="name_first">
                                PASSPORT ISSUE DATE:
                              </span>
                              <span className="last_name">
                                {passenger?.passport_issued_at ?? '-'}
                              </span>
                            </li>
                            <li>
                              <span className="name_first">
                                PASSPORT EXPIRY DATE:
                              </span>
                              <span className="last_name">
                                {passenger?.passport_expiring_at ?? '-'}
                              </span>
                            </li>
                            <li>
                              <span className="name_first">
                                PASSPORT ISSUE COUNTRY:
                              </span>{' '}
                              <span className="last_name">
                                {passenger?.passport_issue_country ?? '-'}
                              </span>
                            </li>
                            <li>
                              <span className="name_first">CONTACT NO:</span>{' '}
                              <span className="last_name">
                                {passenger?.contact_number ?? '-'}
                              </span>
                            </li>

                            <li>
                              <span className="name_first">WHEEL CHAIR:</span>{' '}
                              <span className="last_name">
                                {passenger?.wheel_chair_required === true
                                  ? 'Yes'
                                  : 'No'}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="tour_details_right_sidebar_wrapper">
                <div className="tour_detail_right_sidebar">
                  <div className="tour_details_right_boxed">
                    <div className="tour_details_right_box_heading">
                      <h3>Booking details</h3>
                    </div>
                    <div className="tour_booking_amount_area">
                      <ul>
                        <li>
                          Booking ID: <span>{data?.reference_number}</span>
                        </li>
                        <li>
                          Booking date:{' '}
                          <span>{data?.onward_departure_date}</span>
                        </li>
                        <li>
                          Booking status:{' '}
                          <span
                            style={{
                              color: data?.status === DELETED ? RED : null,
                            }}
                          >
                            {data?.status === DELETED
                              ? CANCELLED_BY_ADMIN
                              : data?.status}
                          </span>
                        </li>
                      </ul>
                      {data?.booking_type === 'One Way' ? (
                        <>
                          <ul>
                            <li>
                              Adult x {countPassengerOnType('Adult')}
                              <span>
                                €
                                {countPassengerOnType('Adult') *
                                  (priceRound(
                                    parseInt(data?.onward_trip_adult_price)
                                  ) || 0.0)}
                              </span>
                            </li>
                            {countPassengerOnType('Child') !== 0 ? (
                              <li>
                                Child x {countPassengerOnType('Child')}
                                <span>
                                  €
                                  {countPassengerOnType('Child') *
                                    (priceRound(
                                      parseInt(data?.onward_trip_child_price)
                                    ) || 0.0)}
                                </span>
                              </li>
                            ) : null}
                            {countPassengerOnType('Infant') !== 0 ? (
                              <li>
                                Infant x {countPassengerOnType('Infant')}
                                <span>
                                  €
                                  {countPassengerOnType('Infant') *
                                    (priceRound(
                                      parseInt(data?.onward_trip_infant_price)
                                    ) || 0.0)}
                                </span>
                              </li>
                            ) : null}
                          </ul>
                          <div className="total_subtotal_booking">
                            <h6 className="remove_coupon_tour">
                              Subtotal{' '}
                              <span>€{priceRound(data.sub_total)}</span>
                            </h6>
                          </div>
                          <div className="total_subtotal_booking">
                            <h6 className="remove_coupon_tour">
                              Total Amount{' '}
                              <span>€{priceRound(data.total)}</span>
                            </h6>
                          </div>
                        </>
                      ) : (
                        <>
                          <ul>
                            <li>
                              Adult x {countPassengerOnType('Adult')}
                              <span>
                                €
                                {countPassengerOnType('Adult') *
                                  (priceRound(
                                    parseInt(data?.onward_trip_adult_price)
                                  ) || 0.0) +
                                  countPassengerOnType('Adult') *
                                    (priceRound(
                                      parseInt(data?.return_trip_adult_price)
                                    ) || 0.0)}
                              </span>
                            </li>
                            {countPassengerOnType('Child') != 0 ? (
                              <li>
                                Child x {countPassengerOnType('Child')}
                                <span>
                                  €
                                  {countPassengerOnType('Child') *
                                    (priceRound(
                                      parseInt(data?.onward_trip_child_price)
                                    ) || 0.0) +
                                    countPassengerOnType('Child') *
                                      (priceRound(
                                        parseInt(data?.return_trip_child_price)
                                      ) || 0.0)}
                                </span>
                              </li>
                            ) : null}
                            {countPassengerOnType('Infant') != 0 ? (
                              <li>
                                Infant x {countPassengerOnType('Infant')}
                                <span>
                                  €
                                  {countPassengerOnType('Infant') *
                                    (priceRound(
                                      parseInt(data?.onward_trip_infant_price)
                                    ) || 0.0) +
                                    countPassengerOnType('Infant') *
                                      (priceRound(
                                        parseInt(data?.return_trip_infant_price)
                                      ) || 0.0)}
                                </span>
                              </li>
                            ) : null}
                          </ul>
                          <div className="total_subtotal_booking">
                            <h6 className="remove_coupon_tour">
                              Subtotal{' '}
                              <span>€{priceRound(data.sub_total)}</span>
                            </h6>
                          </div>
                          {data?.discount !== null &&
                          parseFloat(data?.discount) !== 0.0 ? (
                            <div className="total_subtotal_booking">
                              <h6 className="remove_coupon_tour">
                                Discount{' '}
                                <span>- €{priceRound(data.discount)}</span>
                              </h6>
                            </div>
                          ) : null}
                          <div className="total_subtotal_booking">
                            <h6 className="remove_coupon_tour">
                              Total Amount{' '}
                              <span>€{priceRound(data.total)}</span>
                            </h6>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tour_details_right_sidebar_wrapper">
                <div
                  className="tour_details_right_boxed"
                  style={{
                    height: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Link to={invoiceURL(data?.reference_number)} target="_blank">
                    <h3
                      style={{
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: 20,
                        marginTop: 10,
                      }}
                    >
                      View Invoice
                    </h3>
                  </Link>
                </div>
              </div>
              <div
                className="tour_details_right_sidebar_wrapper"
                style={{ marginTop: 20 }}
              >
                <div
                  className="tour_details_right_boxed"
                  style={{
                    height: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Link
                    to={pnrURL(data?.reference_number, 'all')}
                    target="_blank"
                  >
                    <h3
                      style={{
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: 20,
                        marginTop: 10,
                      }}
                    >
                      Download All PNR
                    </h3>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default BookingDetails;
