import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import RoutesList from '../../constants/RoutesList';
import TopBanner from '../../components/TopBanner/TopBanner';
import Loading from '../../components/Loading/Loading';
import { forgotPassword, resetPassword, verifyOtp } from '../../api/auth';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import InputField from '../../components/InputField/InputField';
import OtpInput from 'react-otp-input';
import { ERROR, SUCCESS, showSnackbar } from '../../../utils';
import ErrorModal from '../../components/ErrorModal';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState({
    username: '',
    stage: 'forgotPassword',
    password: '',
    passwordConfirm: '',
  });
  const [otp, setOtp] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showErrorFields, setShowErrorFields] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const wrapperSetOpenErrorModal = useCallback(
    (val) => {
      setOpenErrorModal(val);
    },
    [setOpenErrorModal]
  );

  const forgotPasswordAction = async (username) => {
    setIsLoading(true);
    try {
      const obj = {
        username,
      };
      const result = await forgotPassword(obj);
      if (result.status === 'ok') {
        setFormFields((formFields) => ({
          ...formFields,
          stage: 'verifyOtp',
        }));
      } else {
        showSnackbar(result.error, ERROR);
      }
    } catch (err) {
      showSnackbar(err, ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOtpAction = async (username, otp) => {
    setIsLoading(true);
    try {
      const obj = {
        username,
        otp,
      };
      const result = await verifyOtp(obj);
      if (result.status === 'ok') {
        setFormFields((formFields) => ({
          ...formFields,
          stage: 'resetPassword',
        }));
      } else {
        showSnackbar(result.error, ERROR);
      }
    } catch (err) {
      showSnackbar(err, ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const resetPasswordAction = async (username, otp, password) => {
    setIsLoading(true);
    try {
      const obj = {
        username,
        otp,
        password,
      };
      const result = await resetPassword(obj);
      if (result.status === 'ok') {
        showSnackbar('Password reset successfully', SUCCESS);
        localStorage.removeItem('Token');
        navigate(RoutesList.login);
      } else {
        setErrors(result?.errors);
        setOpenErrorModal(true);
      }
    } catch (err) {
      showSnackbar(err, ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitAction = () => {
    if (
      formFields.username.length > 0 &&
      formFields.stage === 'forgotPassword'
    ) {
      forgotPasswordAction(formFields.username);
    } else if (otp.length === 6 && formFields.stage === 'verifyOtp') {
      verifyOtpAction(formFields.username, otp);
    } else if (
      formFields.password.length > 0 &&
      formFields.passwordConfirm.length > 0 &&
      formFields.stage === 'resetPassword'
    ) {
      if (formFields.password === formFields.passwordConfirm) {
        resetPasswordAction(formFields.username, otp, formFields.password);
      } else {
        showSnackbar(
          "Password and Confirm Password don't match, Please try again.",
          ERROR
        );
      }
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <TopBanner pagName={'Login'} />
      {formFields.stage === 'forgotPassword' ? (
        <>
          <section id="common_author_area" className="section_padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="common_author_boxed">
                    <div className="common_author_heading">
                      <h3>Forgot password</h3>
                      <h2>Reset you password</h2>
                    </div>
                    <div className="common_author_form">
                      <InputField
                        field={'username'}
                        formFields={formFields}
                        setFormFields={setFormFields}
                        error={
                          showErrorFields
                            ? formFields.username.length === 0
                            : null
                        }
                        helperText={
                          showErrorFields && formFields.username.length === 0
                            ? 'Username can not be blank'
                            : null
                        }
                        placeholder={'Enter username'}
                      />
                      <div className="common_form_submit">
                        <button
                          className="btn btn_theme btn_md"
                          onClick={onSubmitAction}
                        >
                          Send code
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : formFields.stage === 'verifyOtp' ? (
        <>
          <section id="common_author_area" className="section_padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="common_author_boxed">
                    <div className="common_author_heading">
                      <h3>Verify OTP</h3>
                      <h2>Verify your OTP</h2>
                    </div>
                    <div className="common_author_form">
                      <div className="form-group">
                        <div className="otpCont flex spaceBetween">
                          <OtpInput
                            containerStyle={{
                              justifyContent: 'center',
                            }}
                            inputStyle={'otSc form-control'}
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span></span>}
                            renderInput={(props) => (
                              <input className="otSc form-control" {...props} />
                            )}
                          />
                        </div>
                      </div>
                      <div className="common_form_submit">
                        <button
                          className="btn btn_theme btn_md"
                          onClick={onSubmitAction}
                        >
                          Verify OTP
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : formFields.stage === 'resetPassword' ? (
        <>
          <section id="common_author_area" className="section_padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="common_author_boxed">
                    <div className="common_author_heading">
                      <h3>Reset password</h3>
                      <h2>Reset you password</h2>
                    </div>
                    <div className="common_author_form">
                      <InputField
                        type={'password'}
                        field={'password'}
                        formFields={formFields}
                        setFormFields={setFormFields}
                        background="#FFF"
                        error={
                          showErrorFields
                            ? formFields.password.length === 0
                            : null
                        }
                        helperText={
                          showErrorFields && formFields.password.length === 0
                            ? 'It can not be blank'
                            : null
                        }
                        placeholder={'Enter password'}
                      />

                      <InputField
                        type={'password'}
                        field={'passwordConfirm'}
                        formFields={formFields}
                        setFormFields={setFormFields}
                        background="#FFF"
                        error={
                          showErrorFields
                            ? formFields.passwordConfirm.length === 0
                            : null
                        }
                        helperText={
                          showErrorFields &&
                          formFields.passwordConfirm.length === 0
                            ? 'It can not be blank'
                            : null
                        }
                        placeholder={'Enter confirm password'}
                        showPassword={formFields.showPassword}
                      />

                      <div className="common_form_submit">
                        <button
                          className="btn btn_theme btn_md"
                          onClick={onSubmitAction}
                        >
                          Reset password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
      <ErrorModal setOpenErrorModal={setOpenErrorModal} errors={errors} />
      <Footer />
    </>
  );
};

export default ForgotPassword;
