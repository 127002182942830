import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBanner from '../../components/TopBanner/TopBanner';
import Loading from '../../components/Loading/Loading';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Dialog, Button } from '@mui/material';
import { getAirports, getAvailableDates } from '../../api/flight';
import {
  BLACK,
  BUSINESS,
  ECONOMY,
  ERROR,
  FIRSTCLASS,
  SESSION_EXPIRED,
  SOMETHING_WENT_WRONG,
  WHITE,
  showSnackbar,
} from '../../../utils';
import Select from 'react-select';
import format from 'date-fns/format';
import ErrorModal from '../../components/ErrorModal';
import RoutesList from '../../constants/RoutesList';
import RecentBookings from '../../components/RecentBookings';

const Home = () => {
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState({
    from: [],
    fromSelected: {},
    to: [],
    toSelected: {},
    class: ECONOMY,
    seat_count: 1,
    adult: 1,
    children: 0,
    infant: 0,
    availableDates: {},
    availableDatesReturn: {},
  });
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateState, setDateState] = useState(null);
  const [returnDateState, setReturnDateState] = useState(null);
  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const [openDateModal, setOpenDateModal] = useState(false);
  const [openReturnDateModal, setOpenReturnDateModal] = useState(false);
  const [openPassengerModal, setOpenPassengerModal] = useState(false);
  const [isDateLoading, setIsDateLoading] = useState(false);
  const [isDateFull, setIsDateFull] = useState(true);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      background: '#f3f6fd',
      color: '#2b2540',
      fontSize: '18px',
      fontWeight: '500',
      boxShadow: 'none',
      paddingHorizontal: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#2b2540',
      };
    },
    menu: (base) => ({
      ...base,
      borderradius: 0,
      margintop: 0,
    }),
    menulist: (base) => ({
      ...base,
      padding: 0,
    }),
  };

  const handleChangeFrom = (selectedOption) => {
    setFormFields((formFields) => ({
      ...formFields,
      fromSelected: selectedOption,
    }));
  };

  const handleChangeTo = (selectedOption) => {
    setFormFields((formFields) => ({
      ...formFields,
      toSelected: selectedOption,
    }));
  };

  const fetchAirports = async () => {
    setIsLoading(true);
    try {
      const results = await getAirports();
      setFormFields((formFields) => ({
        ...formFields,
        from: results,
      }));
      setFormFields((formFields) => ({
        ...formFields,
        to: results,
      }));
    } catch (err) {
      if (err === 'sessionTimeout') {
        showSnackbar(SESSION_EXPIRED, ERROR);
        localStorage.removeItem('Token');
        navigate(RoutesList.login);
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAvailableDates = async (
    fromSelected,
    toSelected,
    seat_class,
    total,
    departureDate,
    roundTrip = false,
    fromSelectedCountry,
    toSelectedCountry
  ) => {
    setIsDateLoading(true);
    try {
      const results = await getAvailableDates(
        fromSelected,
        toSelected,
        seat_class,
        total,
        departureDate,
        false,
        fromSelectedCountry,
        toSelectedCountry
      );
      setFormFields((formFields) => ({
        ...formFields,
        availableDates: results,
      }));
    } catch (err) {
      if (err === 'sessionTimeout') {
        showSnackbar(SESSION_EXPIRED, ERROR);
        localStorage.removeItem('Token');
        navigate(RoutesList.login);
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsDateLoading(false);
    }
  };

  const fetchAvailableDatesReturn = async (
    fromSelected,
    toSelected,
    seat_class,
    total,
    departureDate,
    roundTrip = true,
    fromSelectedCountry,
    toSelectedCountry
  ) => {
    setIsDateLoading(true);
    try {
      const results = await getAvailableDates(
        fromSelected,
        toSelected,
        seat_class,
        total,
        departureDate,
        true,
        fromSelectedCountry,
        toSelectedCountry
      );
      setFormFields((formFields) => ({
        ...formFields,
        availableDatesReturn: results,
      }));
    } catch (err) {
      if (err === 'sessionTimeout') {
        showSnackbar(SESSION_EXPIRED, ERROR);
        localStorage.removeItem('Token');
        navigate(RoutesList.login);
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsDateLoading(false);
    }
  };

  var groupBy = function (xs, key) {
    return xs?.reduce(function (rv, x) {
      (rv[x['address'][key]] = rv[x['address'][key]] || []).push(x);
      return rv;
    }, {});
  };

  var comuteSelectFieldOptions = () => {
    var groupedData = groupBy(formFields?.from, 'country');
    return Object.entries(groupedData).map((item) => {
      return {
        label: item[0],
        options: item[1].map((airport) => {
          return {
            label: airport.name,
            value: airport.id,
            code: airport.code,
            city: airport.address?.city,
            country: airport.address?.country,
          };
        }),
      };
    });
  };

  const handleClose = () => {
    setOpenDateModal(!openDateModal);
  };

  const handleCloseReturn = () => {
    setOpenReturnDateModal(!openReturnDateModal);
  };

  const handleClosePassenger = () => {
    setOpenPassengerModal(!openPassengerModal);
  };

  const changeDate = (e) => {
    setDateState(e);
    handleClose();
  };

  const changeReturnDate = (e) => {
    setReturnDateState(e);
    handleCloseReturn();
  };

  const formattedDate = (date) => {
    let f_date = date.split(' ')[0].split('-');
    return `${f_date[1]}-${f_date[2]}-${f_date[0]}`;
  };

  const show = ({ date, view }) => {
    let formattedDates = Object.keys(formFields.availableDates).map((date) =>
      formattedDate(date)
    );
    date =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '-' +
      date.getFullYear();
    if (formattedDates.includes(date)) {
      return (
        <div
          style={{
            paddingTop: 5,
            fontWeight: 'bold',
            color:
              Object.values(formFields.availableDates)[
                formattedDates.indexOf(date)
              ]?.seats_available === 0
                ? 'red'
                : dateState === date
                ? '#fff'
                : Object.values(formFields.availableDates)[
                    formattedDates.indexOf(date)
                  ]?.seats_available !== 0
                ? '#000'
                : null,
          }}
        >
          {Object.values(formFields.availableDates)[
            formattedDates.indexOf(date)
          ]?.seats_available === 0
            ? 'Full'
            : `€${
                Object.values(formFields.availableDates)[
                  formattedDates.indexOf(date)
                ]?.price
              }`}
        </div>
      );
    }
    return null;
  };

  const disabledTile = (date) => {
    let formattedDates = Object.keys(formFields.availableDates).map((d) =>
      formattedDate(d)
    );
    date =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '-' +
      date.getFullYear();
    if (
      Object.values(formFields.availableDates)[formattedDates.indexOf(date)]
        ?.seats_available === 0
    ) {
      return formattedDates;
    } else {
      return !formattedDates.includes(date);
    }
  };

  const showReturn = ({ date, view }) => {
    let formattedDates = Object.keys(formFields.availableDatesReturn).map(
      (date) => formattedDate(date)
    );
    date =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '-' +
      date.getFullYear();
    if (formattedDates.includes(date)) {
      return (
        <div
          style={{
            paddingTop: 5,
            fontWeight: 'bold',
            color:
              Object.values(formFields.availableDatesReturn)[
                formattedDates.indexOf(date)
              ]?.seats_available === 0
                ? 'red'
                : returnDateState === date
                ? '#fff'
                : Object.values(formFields.availableDatesReturn)[
                    formattedDates.indexOf(date)
                  ]?.seats_available !== 0
                ? '#000'
                : null,
          }}
        >
          {Object.values(formFields.availableDatesReturn)[
            formattedDates.indexOf(date)
          ]?.seats_available === 0
            ? 'Full'
            : `€${
                Object.values(formFields.availableDatesReturn)[
                  formattedDates.indexOf(date)
                ]?.price
              }`}
        </div>
      );
    }
    return null;
  };

  const disabledTileReturn = (date) => {
    let formattedDates = Object.keys(formFields.availableDatesReturn).map((d) =>
      formattedDate(d)
    );
    date =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '-' +
      date.getFullYear();
    if (
      Object.values(formFields.availableDatesReturn)[
        formattedDates.indexOf(date)
      ]?.seats_available === 0
    ) {
      return formattedDates;
    } else {
      return !formattedDates.includes(date);
    }
  };

  useEffect(() => {
    if (
      formFields.toSelected.code !== undefined &&
      formFields.fromSelected.code !== undefined
    ) {
      fetchAvailableDates(
        formFields.fromSelected.value,
        formFields.toSelected.value,
        formFields.class,
        formFields.adult + formFields.children + formFields.infant,
        format(dateState !== null ? dateState : new Date(), 'yyyy-MM-dd'),
        false,
        formFields.fromSelected.country,
        formFields.toSelected.country
      );
    }
  }, [formFields.toSelected.code, formFields.fromSelected.code]);

  useEffect(() => {
    if (
      formFields.toSelected.code !== undefined &&
      formFields.fromSelected.code !== undefined &&
      dateState !== null &&
      isRoundTrip
    ) {
      fetchAvailableDatesReturn(
        formFields.toSelected.value,
        formFields.fromSelected.value,
        formFields.class,
        formFields.adult + formFields.children + formFields.infant,
        format(dateState, 'yyyy-MM-dd'),
        true,
        formFields.toSelected.country,
        formFields.fromSelected.country
      );
    }
  }, [
    formFields.toSelected.code,
    formFields.fromSelected.code,
    isRoundTrip,
    dateState,
  ]);

  useEffect(() => {
    fetchAirports();
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <TopBanner pagName={'Flight Search'} />

      <Dialog
        open={openDateModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <Calendar
            value={dateState}
            onChange={changeDate}
            tileContent={show}
            minDate={new Date()}
            tileDisabled={({ date }) => disabledTile(date)}
            tileClassName={
              isDateFull ? 'react-calendar__tile-full' : 'react-calendar__tile'
            }
          />
        </div>
      </Dialog>

      <Dialog
        open={openReturnDateModal}
        onClose={handleCloseReturn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <Calendar
            value={returnDateState}
            onChange={changeReturnDate}
            tileContent={showReturn}
            minDate={new Date()}
            tileDisabled={({ date }) => disabledTileReturn(date)}
            tileClassName={
              isDateFull ? 'react-calendar__tile-full' : 'react-calendar__tile'
            }
          />
        </div>
      </Dialog>

      <Dialog
        open={openPassengerModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          className="passenger-modal dropdown_passenger_area dropdown-menu-show"
          aria-labelledby="dropdownMenuButton1"
        >
          <div className="traveller-calulate-persons">
            <div className="passengers">
              <h6>Passengers</h6>
              <div className="passengers-types">
                <div className="passengers-type">
                  <div className="text">
                    <span className="count pcount">{formFields.adult}</span>
                    <div className="type-label">
                      <p>Adult</p>
                      <span>12+ yrs</span>
                    </div>
                  </div>
                  <div className="button-set">
                    <button
                      type="button"
                      className="btn-add"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setFormFields((formFields) => ({
                          ...formFields,
                          adult: formFields.adult + 1,
                        }));
                      }}
                    >
                      <i className="fas fa-plus" />
                    </button>
                    <button
                      type="button"
                      className="btn-subtract"
                      onClick={() => {
                        if (formFields.adult > 1) {
                          setFormFields((formFields) => ({
                            ...formFields,
                            adult: formFields.adult - 1,
                          }));
                        }
                      }}
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="passengers-type">
                  <div className="text">
                    <span className="count ccount">{formFields.children}</span>
                    <div className="type-label">
                      <p className="fz14 mb-xs-0">Children</p>
                      <span>2 - Less than 12 yrs</span>
                    </div>
                  </div>
                  <div className="button-set">
                    <button
                      type="button"
                      className="btn-add-c"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setFormFields((formFields) => ({
                          ...formFields,
                          children: formFields.children + 1,
                        }));
                      }}
                    >
                      <i className="fas fa-plus" />
                    </button>
                    <button
                      type="button"
                      className="btn-subtract-c"
                      onClick={() => {
                        if (formFields.children >= 1) {
                          setFormFields((formFields) => ({
                            ...formFields,
                            children: formFields.children - 1,
                          }));
                        }
                      }}
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="passengers-type">
                  <div className="text">
                    <span className="count incount">{formFields.infant}</span>
                    <div className="type-label">
                      <p className="fz14 mb-xs-0">Infant</p>
                      <span>Less than 2 yrs</span>
                    </div>
                  </div>
                  <div className="button-set">
                    <button
                      type="button"
                      className="btn-add-in"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setFormFields((formFields) => ({
                          ...formFields,
                          infant: formFields.infant + 1,
                        }));
                      }}
                    >
                      <i className="fas fa-plus" />
                    </button>
                    <button
                      type="button"
                      className="btn-subtract-in"
                      onClick={() => {
                        if (formFields.infant >= 1) {
                          setFormFields((formFields) => ({
                            ...formFields,
                            infant: formFields.infant - 1,
                          }));
                        }
                      }}
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="cabin-selection">
              <h6>Cabin Class</h6>
              <div className="cabin-list">
                <button
                  type="button"
                  className={
                    formFields.class === ECONOMY
                      ? 'label-select-btn active'
                      : 'label-select-btn'
                  }
                  onClick={() => {
                    setFormFields((formFields) => ({
                      ...formFields,
                      class: ECONOMY,
                    }));
                  }}
                >
                  <span className="muiButton-label">Economy</span>
                </button>
                <button
                  type="button"
                  className={
                    formFields.class === BUSINESS
                      ? 'label-select-btn active'
                      : 'label-select-btn'
                  }
                  onClick={() => {
                    setFormFields((formFields) => ({
                      ...formFields,
                      class: BUSINESS,
                    }));
                  }}
                >
                  <span className="muiButton-label">Business</span>
                </button>
                <button
                  type="button"
                  className={
                    formFields.class === FIRSTCLASS
                      ? 'label-select-btn active'
                      : 'label-select-btn'
                  }
                  onClick={() => {
                    setFormFields((formFields) => ({
                      ...formFields,
                      class: FIRSTCLASS,
                    }));
                  }}
                >
                  <span className="muiButton-label">First Class</span>
                </button>
              </div>
            </div>
          </div>
          <div className="top_form_search_button">
            <Button
              onClick={() => {
                fetchAvailableDates(
                  formFields.fromSelected.value,
                  formFields.toSelected.value,
                  formFields.class,
                  formFields.adult + formFields.children + formFields.infant,
                  format(
                    dateState !== null ? dateState : new Date(),
                    'yyyy-MM-dd'
                  ),
                  false,
                  formFields.fromSelected.country,
                  formFields.toSelected.country
                );
                handleClosePassenger();
              }}
              variant="contained"
              className="btn btn_theme btn_md"
              style={{
                backgroundColor: '#343a40',
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Submit
            </Button>
            <Button
              onClick={() => {
                setFormFields((formFields) => ({
                  ...formFields,
                  adult: 1,
                }));
                setFormFields((formFields) => ({
                  ...formFields,
                  children: 0,
                }));
                setFormFields((formFields) => ({
                  ...formFields,
                  infant: 0,
                }));
                setFormFields((formFields) => ({
                  ...formFields,
                  class: ECONOMY,
                }));
                handleClosePassenger();
              }}
              variant="contained"
              className="btn btn_theme btn_md"
              style={{ backgroundColor: '#343a40', fontSize: 14 }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>

      <ErrorModal
        openErrorModal={openErrorModal}
        setOpenErrorModal={setOpenErrorModal}
        errors={errors}
      />
      <section id="theme_search_form_tour" style={{ marginTop: 50 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="theme_search_form_area">
                <div className="theme_search_form_tabbtn">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        disabled
                        className="nav-link active"
                        id="flights-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#flights"
                        type="button"
                        role="tab"
                        aria-controls="flights"
                        aria-selected="true"
                      >
                        <i className="fas fa-plane-departure" />
                        Flights
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="flights"
                    role="tabpanel"
                    aria-labelledby="flights-tab"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="flight_categories_search">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button
                                className={
                                  !isRoundTrip ? 'nav-link active' : 'nav-link'
                                }
                                id="oneway-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#oneway_flight"
                                type="button"
                                role="tab"
                                aria-controls="oneway_flight"
                                aria-selected="true"
                                style={{
                                  backgroundColor: !isRoundTrip ? BLACK : WHITE,
                                  color: !isRoundTrip ? WHITE : BLACK,
                                }}
                                onClick={() => {
                                  setIsRoundTrip(false);
                                }}
                              >
                                One Way
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className={
                                  isRoundTrip ? 'nav-link active' : 'nav-link'
                                }
                                id="roundtrip-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#roundtrip"
                                type="button"
                                role="tab"
                                style={{
                                  backgroundColor: isRoundTrip ? BLACK : WHITE,
                                  color: isRoundTrip ? WHITE : BLACK,
                                }}
                                aria-controls="roundtrip"
                                aria-selected="false"
                                onClick={() => {
                                  setIsRoundTrip(true);
                                }}
                              >
                                Round Trip
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content" id="myTabContent1">
                      <div
                        className="tab-pane fade show active"
                        id="oneway_flight"
                        role="tabpanel"
                        aria-labelledby="oneway-tab"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="oneway_search_form">
                              <form action="#!">
                                <div className="row">
                                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                    <div className="flight_Search_boxed">
                                      <p>From</p>
                                      <Select
                                        // value={formFields.fromSelected}
                                        onChange={handleChangeFrom}
                                        styles={customStyles}
                                        components={{
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                        options={comuteSelectFieldOptions()}
                                        placeholder="Airport Name"
                                      />
                                      {formFields.fromSelected.code !==
                                      undefined ? (
                                        <span>
                                          {formFields.fromSelected.code},{' '}
                                          {formFields.fromSelected.city}
                                        </span>
                                      ) : (
                                        <span>Select airport</span>
                                      )}
                                      <div className="plan_icon_posation">
                                        <i className="fas fa-plane-departure" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                    <div className="flight_Search_boxed">
                                      <p>To</p>
                                      <Select
                                        onChange={handleChangeTo}
                                        styles={customStyles}
                                        components={{
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                        options={comuteSelectFieldOptions()}
                                        placeholder="Airport Name"
                                      />
                                      {formFields.toSelected.code !==
                                      undefined ? (
                                        <span>
                                          {formFields.toSelected.code},{' '}
                                          {formFields.toSelected.city}
                                        </span>
                                      ) : (
                                        <span>Select airport</span>
                                      )}
                                      <div className="plan_icon_posation">
                                        <i className="fas fa-plane-arrival" />
                                      </div>
                                      <div className="range_plan">
                                        <i className="fas fa-exchange-alt" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2  col-md-6 col-sm-12 col-12">
                                    <div className="flight_Search_boxed dropdown_passenger_area">
                                      <p>Passenger, Class </p>
                                      <div className="dropdown">
                                        <button
                                          className="dropdown-toggle final-count"
                                          data-toggle="dropdown"
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          onClick={() => {
                                            if (
                                              formFields.toSelected.code !==
                                                undefined &&
                                              formFields.fromSelected.code !==
                                                undefined
                                            ) {
                                              setOpenPassengerModal(true);
                                            } else {
                                              showSnackbar(
                                                'Select deaparture and arrival airport',
                                                'error'
                                              );
                                            }
                                          }}
                                          style={{
                                            fontSize: 18,
                                            paddingBlock: 8,
                                          }}
                                        >
                                          {formFields.adult +
                                            formFields.children +
                                            formFields.infant}{' '}
                                          Passenger
                                        </button>
                                      </div>
                                      <span>
                                        {formFields.class === ECONOMY
                                          ? 'Economy'
                                          : formFields.class === BUSINESS
                                          ? 'Business'
                                          : formFields.class === FIRSTCLASS
                                          ? 'First Class'
                                          : 'Economy'}
                                      </span>
                                    </div>
                                  </div>
                                  {!isRoundTrip ? (
                                    <div
                                      onClick={() => {
                                        if (
                                          !isDateLoading &&
                                          formFields.fromSelected.code !==
                                            undefined &&
                                          formFields.toSelected.code !==
                                            undefined
                                        ) {
                                          setOpenDateModal(true);
                                        } else if (
                                          formFields.fromSelected.code ===
                                            undefined &&
                                          formFields.toSelected.code ===
                                            undefined
                                        ) {
                                          showSnackbar(
                                            'Select deaparture and arrival airport',
                                            'error'
                                          );
                                        }
                                      }}
                                      className="col-lg-4  col-md-6 col-sm-12 col-12"
                                    >
                                      <div className="form_search_date">
                                        <div className="flight_Search_boxed date_flex_area">
                                          <div className="Journey_date">
                                            <p>Journey date</p>
                                            {!isDateLoading ? (
                                              <span
                                                style={styles.loadingDateTxt}
                                              >
                                                {dateState !== null
                                                  ? format(
                                                      dateState,
                                                      'dd-MM-yyyy'
                                                    )
                                                  : 'DD-MM-YYYY'}
                                              </span>
                                            ) : (
                                              <span
                                                style={styles.loadingDateTxt}
                                              >
                                                Loading...
                                              </span>
                                            )}
                                            <span>
                                              {dateState !== null
                                                ? format(
                                                    dateState,
                                                    'dd-MM-yyyy'
                                                  )
                                                : 'Select Date'}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-lg-4  col-md-6 col-sm-12 col-12">
                                      <div className="form_search_date">
                                        <div className="flight_Search_boxed date_flex_area">
                                          <div
                                            onClick={() => {
                                              if (
                                                formFields.toSelected.code !==
                                                  undefined &&
                                                formFields.fromSelected.code !==
                                                  undefined
                                              ) {
                                                setOpenDateModal(true);
                                              } else {
                                                showSnackbar(
                                                  'Select deaparture and arrival airport',
                                                  'error'
                                                );
                                              }
                                            }}
                                            className="Journey_date"
                                          >
                                            <p>Journey date</p>
                                            {!isDateLoading ? (
                                              <span
                                                style={styles.loadingDateTxt}
                                              >
                                                {dateState !== null
                                                  ? format(
                                                      dateState,
                                                      'dd-MM-yyyy'
                                                    )
                                                  : 'DD-MM-YYYY'}
                                              </span>
                                            ) : (
                                              <span
                                                style={styles.loadingDateTxt}
                                              >
                                                Loading...
                                              </span>
                                            )}
                                            <span>
                                              {dateState !== null
                                                ? format(
                                                    dateState,
                                                    'dd-MM-yyyy'
                                                  )
                                                : 'Select Date'}
                                            </span>
                                          </div>
                                          <div
                                            onClick={() => {
                                              if (
                                                formFields.toSelected.code !==
                                                  undefined &&
                                                formFields.fromSelected.code !==
                                                  undefined
                                              ) {
                                                setOpenReturnDateModal(true);
                                              } else {
                                                showSnackbar(
                                                  'Select deaparture and arrival airport',
                                                  'error'
                                                );
                                              }
                                            }}
                                            className="Journey_date"
                                          >
                                            <p>Return date</p>
                                            {!isDateLoading ? (
                                              <span
                                                style={{
                                                  fontSize: 18,
                                                  fontWeight: 'bold',
                                                  paddingBlock: 8,
                                                  paddingRight: !isDateLoading
                                                    ? 100
                                                    : 0,
                                                  color: '#212529',
                                                }}
                                              >
                                                {returnDateState !== null
                                                  ? format(
                                                      returnDateState,
                                                      'dd-MM-yyyy'
                                                    )
                                                  : 'DD-MM-YYYY'}
                                              </span>
                                            ) : (
                                              <span
                                                style={{
                                                  fontSize: 18,
                                                  fontWeight: 500,
                                                  paddingBlock: 8,
                                                  color: '#2b2540',
                                                  paddingRight: isDateLoading
                                                    ? 100
                                                    : 0,
                                                }}
                                              >
                                                Loading...
                                              </span>
                                            )}
                                            <span>
                                              {returnDateState !== null
                                                ? format(
                                                    returnDateState,
                                                    'dd-MM-yyyy'
                                                  )
                                                : 'Select Date'}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className="top_form_search_button">
                                    <Button
                                      onClick={() => {
                                        if (
                                          formFields.fromSelected.code !==
                                            undefined &&
                                          formFields.toSelected.code !==
                                            undefined &&
                                          formFields.toSelected.code !== null &&
                                          formFields.fromSelected.code !=
                                            null &&
                                          dateState !== null
                                        ) {
                                          if (!isRoundTrip) {
                                            navigate(
                                              RoutesList.flightsResults,
                                              {
                                                state: {
                                                  item: {
                                                    toLocation:
                                                      formFields.toSelected
                                                        .value,
                                                    toLocationCountry:
                                                      formFields.toSelected
                                                        .country,
                                                    fromLocation:
                                                      formFields.fromSelected
                                                        .value,
                                                    fromLocationCountry:
                                                      formFields.fromSelected
                                                        .country,
                                                    seatClass: formFields.class,
                                                    journeyDate: format(
                                                      dateState,
                                                      'yyyy-MM-dd'
                                                    ),
                                                    adultsCount:
                                                      formFields.adult,
                                                    childCount:
                                                      formFields.children,
                                                    infantsCount:
                                                      formFields.infant,
                                                    cameFrom: 'flightSearch',
                                                  },
                                                },
                                              }
                                            );
                                          } else if (returnDateState !== null) {
                                            navigate(
                                              RoutesList.flightsResults,
                                              {
                                                state: {
                                                  item: {
                                                    toLocation:
                                                      formFields.toSelected
                                                        .value,
                                                    toLocationCountry:
                                                      formFields.toSelected
                                                        .country,
                                                    fromLocation:
                                                      formFields.fromSelected
                                                        .value,
                                                    fromLocationCountry:
                                                      formFields.fromSelected
                                                        .country,
                                                    seatClass: formFields.class,
                                                    journeyDate: format(
                                                      dateState,
                                                      'yyyy-MM-dd'
                                                    ),
                                                    returnJourneyDate: format(
                                                      returnDateState,
                                                      'yyyy-MM-dd'
                                                    ),
                                                    adultsCount:
                                                      formFields.adult,
                                                    childCount:
                                                      formFields.children,
                                                    infantsCount:
                                                      formFields.infant,
                                                    cameFrom: 'flightSearch',
                                                  },
                                                },
                                              }
                                            );
                                          } else {
                                            showSnackbar(
                                              'Please select arrival date',
                                              'error'
                                            );
                                          }
                                        } else if (!isRoundTrip) {
                                          showSnackbar(
                                            'Please select departure date, from airport & to aiport',
                                            'error'
                                          );
                                        } else {
                                          showSnackbar(
                                            'Please select departure and arrival date',
                                            'error'
                                          );
                                        }
                                      }}
                                      variant="contained"
                                      className="btn btn_theme btn_md"
                                      style={{ backgroundColor: BLACK }}
                                    >
                                      Search
                                    </Button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <RecentBookings />

      <Footer />
    </>
  );
};

const styles = {
  loadingDateTxt: {
    fontSize: 18,
    fontWeight: 500,
    paddingBlock: 8,
    color: '#2b2540',
  },
};

export default Home;
