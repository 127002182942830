import React, { useState } from 'react';
import {
  CANCELLED_BY_ADMIN,
  CONFIRMED,
  DELETED,
  GREEN,
  ORANGE,
  RED,
  priceRound,
} from '../../utils';
import { useNavigate } from 'react-router-dom';
import RoutesList from '../constants/RoutesList';

export default function Dashboard(data) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const pagesData = () => {
    let chunkSize = 5;
    let chunks = [];
    let reverseArray = data?.data?.reverse();
    for (let i = 0; i < reverseArray?.length; i += chunkSize) {
      chunks?.push(reverseArray?.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const pageData = () => {
    return pagesData()[currentPage - 1];
  };

  const onPressAction = (id) => {
    navigate(`${RoutesList.bookingDetails}?booking_id=${id}`);
  };

  return (
    <div className="col-lg-8">
      <div className="dashboard_main_top">
        <div className="row">
          <div className="col-lg-6">
            <div className="dashboard_top_boxed">
              <div className="dashboard_top_icon">
                <i className="fas fa-shopping-bag"></i>
              </div>
              <div className="dashboard_top_text">
                <h3>Total bookings</h3>
                <h1>{data?.data?.length}</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="dashboard_top_boxed">
              <div className="dashboard_top_icon">
                <i className="fas fa-sync"></i>
              </div>
              <div className="dashboard_top_text">
                <h3>Pending bookings</h3>
                <h1>
                  {
                    data?.data?.filter((pax) => pax?.status === 'Pending')
                      ?.length
                  }
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard_common_table">
        <h3>Recent bookings</h3>
        <div className="table-responsive-lg table_common_area">
          <table className="table">
            <thead>
              <tr>
                <th>Booking ID</th>
                <th>Destination</th>
                <th>Departure Date</th>
                <th>Booking amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {pageData()?.map((booking) => {
                return (
                  <tr>
                    <td>{booking?.reference_number ?? '-'}</td>
                    <td>
                      {booking?.onward_departure_airport?.code}
                      {' - '}
                      {booking.onward_arrival_airport?.code}
                    </td>
                    <td>{booking?.onward_departure_date}</td>
                    <td>
                      €
                      {booking.total !== undefined
                        ? priceRound(booking?.total)
                        : 0}
                    </td>
                    <td
                      className="complete"
                      style={{
                        fontWeight: 'bold',
                        color:
                          booking?.status === CONFIRMED
                            ? GREEN
                            : booking?.status === DELETED
                            ? RED
                            : ORANGE,
                      }}
                    >
                      {booking?.status === DELETED
                        ? CANCELLED_BY_ADMIN
                        : booking?.status}
                    </td>
                    <td>
                      <i
                        onClick={() => {
                          onPressAction(booking?.reference_number);
                        }}
                        className="fas fa-eye"
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination_area">
        <ul className="pagination">
          {pagesData()?.map((data, index) => {
            return (
              <li className="page-item">
                <a
                  className="page-link"
                  onClick={() => {
                    setCurrentPage(index + 1);
                  }}
                >
                  {index + 1}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
