import React from "react";
import { Paper } from "@mui/material";

const styles = {
  wrapper: {
    color: "white",
    height: "100vh",
    position: "relative",
    width: "100%",
  },
  container: {
    position: "absolute",
    zIndex: 1,
    top: "10%",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  paper: {
    alignItems: "center",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    marginTop: 64,
    padding: 24,
    position: "relative",
    zIndex: 1,
  },
};

export const GlobalErrorPage = () => {
  return (
    <div style={styles.wrapper}>
      <div style={styles.container}>
        <Paper style={styles.paper}>
          <p>The application has encountered an unknown error.</p>
        </Paper>
      </div>
    </div>
  );
};
