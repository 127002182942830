import React, { useState, useEffect } from 'react';
import {
  CANCELLED_BY_ADMIN,
  CONFIRMED,
  DELETED,
  ERROR,
  GREEN,
  ORANGE,
  RED,
  SESSION_EXPIRED,
  SOMETHING_WENT_WRONG,
  priceRound,
  showSnackbar,
} from '../../utils';
import { useNavigate } from 'react-router-dom';
import { getAgentData } from '../api/agent';
import Loading from './Loading/Loading';
import RoutesList from '../constants/RoutesList';

export default function RecentBookings() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const results = await getAgentData();
      setData(results?.bookings);
    } catch (err) {
      if (err === 'sessionTimeout') {
        showSnackbar(SESSION_EXPIRED, ERROR);
        localStorage.removeItem('Token');
        navigate(RoutesList.login);
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onPressAction = (id) => {
    navigate(`${RoutesList.bookingDetails}?booking_id=${id}`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section id="dashboard_main_arae" className="section_padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard_common_table">
              <h3>Recent bookings</h3>
              <div className="table-responsive-lg table_common_area">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Booking ID</th>
                      <th>Destination</th>
                      <th>Departure Date</th>
                      <th>Booking amount</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      .slice(-5)
                      .reverse()
                      .map((booking) => {
                        return (
                          <tr>
                            <td>{booking?.reference_number ?? '-'}</td>
                            <td>
                              {booking?.onward_departure_airport?.code}
                              {' - '}
                              {booking.onward_arrival_airport?.code}
                            </td>
                            <td>{booking?.onward_departure_date}</td>
                            <td>
                              €
                              {booking.total !== undefined
                                ? priceRound(booking?.total)
                                : 0}
                            </td>

                            <td
                              className="complete"
                              style={{
                                fontWeight: 'bold',
                                color:
                                  booking?.status === CONFIRMED
                                    ? GREEN
                                    : booking?.status === DELETED
                                    ? RED
                                    : ORANGE,
                              }}
                            >
                              {booking?.status === DELETED
                                ? CANCELLED_BY_ADMIN
                                : booking?.status}
                            </td>
                            <td>
                              <i
                                onClick={() => {
                                  onPressAction(booking?.reference_number);
                                }}
                                className="fas fa-eye"
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
