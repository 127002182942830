import React, { useEffect, useState } from 'react';
import {
  SESSION_EXPIRED,
  SOMETHING_WENT_WRONG,
  ERROR,
  showSnackbar,
} from '../../../utils';
import format from 'date-fns/format';
import ResultCard from './ResultCard';
import DateCard from './DateCard';
import RoutesList from '../../constants/RoutesList';
import { getFlightResults } from '../../api/flight';
import { useNavigate } from 'react-router-dom';

const FlightsColumn = ({
  roundTrip,
  date = null,
  params,
  setFare,
  selectedFare,
  journeyDate,
}) => {
  const navigate = useNavigate();
  const [flights, setFlights] = useState([]);
  const getFlights = async (date) => {
    var requriredSeats = params.adultsCount + params.childCount;
    try {
      const obj = {
        toLocation: roundTrip ? params.fromLocation : params.toLocation,
        fromLocation: roundTrip ? params.toLocation : params.fromLocation,
        seatClass: params.seatClass,
        journeyDate: format(new Date(date), 'yyyy-MM-dd'),
        requriredSeats,
        toLocationCountry: roundTrip
          ? params?.fromLocationCountry
          : params?.toLocationCountry,
        fromLocationCountry: roundTrip
          ? params?.toLocationCountry
          : params?.fromLocationCountry,
      };
      const result = await getFlightResults(obj);
      setFlights(result);
    } catch (err) {
      if (err === 'sessionTimeout') {
        showSnackbar(SESSION_EXPIRED, ERROR);
        localStorage.removeItem('Token');
        navigate(RoutesList.login);
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
    }
  };

  useEffect(() => {
    getFlights(date);
  }, [date]);

  return (
    <div style={{ flex: 2 }}>
      {date !== null ? (
        <>
          <DateCard date={date} />
          {flights.map((flight) => {
            return (
              <ResultCard
                flight={flight}
                params={params}
                setFare={setFare}
                selectedFare={selectedFare}
              />
            );
          })}
        </>
      ) : (
        <div style={{ height: '100%', width: '100%' }} />
      )}
    </div>
  );
};

export default FlightsColumn;
