import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CANCELLED_BY_ADMIN,
  CONFIRMED,
  DELETED,
  ERROR,
  GREEN,
  ORANGE,
  RED,
  SESSION_EXPIRED,
  SOMETHING_WENT_WRONG,
  showSnackbar,
} from '../../../utils';
import Header from '../../components/Header/Header';
import TopBanner from '../../components/TopBanner/TopBanner';
import Footer from '../../components/Footer/Footer';
import { priceRound } from '../../../utils';
import { getAirports, getCarriers } from '../../api/flight';
import format from 'date-fns/format';
import RoutesList from '../../constants/RoutesList';
import { getBookings } from '../../api/booking';

export default function BookingList() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [bookings, setBookings] = useState([]);

  const onPressAction = (id) => {
    navigate(`${RoutesList.bookingDetails}?booking_id=${id}`);
  };

  const [formFields, setFormFields] = useState({
    from: [],
    fromAirport: 'Select Airport',
    toAirport: 'Select Airport',
    to: [],
    carriers: [],
    airline: 'Select Airline',
    status: 'Select Status',
    departureFrom: '',
    departureTo: '',
    bookingFrom: '',
    bookingTo: '',
  });

  const fetchBookings = async () => {
    setIsLoading(true);
    try {
      let obj = {
        toAirport:
          formFields.toAirport === 'Select Airport' ? '' : formFields.toAirport,
        fromAirport:
          formFields.fromAirport === 'Select Airport'
            ? ''
            : formFields.fromAirport,
        airline:
          formFields.airline === 'Select Airline' ? '' : formFields.airline,
        departureFrom:
          formFields.departureFrom === 'Departure From'
            ? ''
            : formFields.departureFrom,
        departureTo:
          formFields.departureTo === 'Departure To'
            ? ''
            : formFields.departureTo,
        status: formFields.status === 'Select Status' ? '' : formFields.status,
        bookingFrom: formFields.bookingFrom,
        bookingTo: formFields.bookingTo,
      };
      const results = await getBookings(obj);
      setBookings(results);
    } catch (err) {
      if (err === 'sessionTimeout') {
        showSnackbar(SESSION_EXPIRED, ERROR);
        localStorage.removeItem('Token');
        navigate(RoutesList.login);
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAirports = async () => {
    setIsLoading(true);
    try {
      const results = await getAirports();
      setFormFields((formFields) => ({
        ...formFields,
        from: results,
      }));
      setFormFields((formFields) => ({
        ...formFields,
        to: results,
      }));
    } catch (err) {
      if (err == 'sessionTimeout') {
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCarriers = async () => {
    setIsLoading(true);
    try {
      const results = await getCarriers();
      setFormFields((formFields) => ({
        ...formFields,
        carriers: results,
      }));
    } catch (err) {
      if (err == 'sessionTimeout') {
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAirports();
    fetchCarriers();
  }, []);

  useEffect(() => {
    fetchBookings();
  }, [
    formFields.toAirport,
    formFields.fromAirport,
    formFields.airline,
    formFields.departureFrom,
    formFields.departureTo,
    formFields.status,
    formFields.bookingFrom,
    formFields.bookingTo,
  ]);

  return (
    <>
      <Header />
      <TopBanner pagName={'Manage Bookings'} />
      <section id="explore_area" className="section_padding">
        <div className="container">
          <div className="dashboard_common_table">
            <h3>View Flight Bookings</h3>
            <div className="profile_update_form">
              <form id="profile_form_area">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label
                        className="form-check-label"
                        for="flexRadioDefault3"
                      >
                        Airport From
                      </label>
                      <select
                        className="form-control form-select bg_input"
                        style={{
                          backgroundColor: '#fff',
                        }}
                        value={formFields.fromAirport}
                        onChange={(e) => {
                          setFormFields((formFields) => ({
                            ...formFields,
                            fromAirport: e.target.value,
                          }));
                        }}
                      >
                        <option value="Select Airport">Select Airport</option>
                        {formFields.from.map((airport) => {
                          return (
                            <option value={airport?.id}>{airport?.name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label
                        className="form-check-label"
                        for="flexRadioDefault3"
                      >
                        Airport To
                      </label>
                      <select
                        className="form-control form-select bg_input"
                        style={{
                          backgroundColor: '#fff',
                        }}
                        value={formFields.toAirport}
                        onChange={(e) => {
                          setFormFields((formFields) => ({
                            ...formFields,
                            toAirport: e.target.value,
                          }));
                        }}
                      >
                        <option value="Select Airport">Select Airport</option>
                        {formFields.from.map((airport) => {
                          return (
                            <option value={airport?.id}>{airport?.name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label
                        className="form-check-label"
                        for="flexRadioDefault3"
                      >
                        Airline
                      </label>
                      <select
                        className="form-control form-select bg_input"
                        style={{
                          backgroundColor: '#fff',
                        }}
                        value={formFields.airline}
                        onChange={(e) => {
                          setFormFields((formFields) => ({
                            ...formFields,
                            airline: e.target.value,
                          }));
                        }}
                      >
                        <option value="Select Airline">Select Airline</option>
                        {formFields.carriers.map((airline) => {
                          return (
                            <option value={airline?.id}>{airline?.name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label
                        className="form-check-label"
                        for="flexRadioDefault3"
                      >
                        Status
                      </label>
                      <select
                        className="form-control form-select bg_input"
                        style={{
                          backgroundColor: '#fff',
                        }}
                        value={formFields.status}
                        onChange={(e) => {
                          setFormFields((formFields) => ({
                            ...formFields,
                            status: e.target.value,
                          }));
                        }}
                      >
                        <option value="">Select Status</option>
                        <option value="Pending">Pending</option>
                        <option value="Confirmed">Confirmed</option>
                        <option value="Hold">Hold</option>
                        <option value={DELETED}>{CANCELLED_BY_ADMIN}</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label
                        className="form-check-label"
                        for="flexRadioDefault3"
                      >
                        Booking Date From
                      </label>
                      <input
                        type="date"
                        style={{
                          backgroundColor: '#fff',
                        }}
                        max={format(new Date(), 'yyyy-MM-dd')}
                        onChange={(e) => {
                          setFormFields((formFields) => ({
                            ...formFields,
                            bookingFrom: e.target.value,
                          }));
                        }}
                        className="form-control bg_input"
                        placeholder="Booking From"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label
                        className="form-check-label"
                        for="flexRadioDefault3"
                      >
                        Booking Date To
                      </label>
                      <input
                        type="date"
                        style={{
                          backgroundColor: '#fff',
                        }}
                        max={format(new Date(), 'yyyy-MM-dd')}
                        onChange={(e) => {
                          setFormFields((formFields) => ({
                            ...formFields,
                            bookingTo: e.target.value,
                          }));
                        }}
                        className="form-control bg_input"
                        placeholder="Booking to"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>

            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <span style={{ fontSize: 22, fontWeight: 'bold' }}>
                  Loading Data...
                </span>
              </div>
            ) : (
              <div className="dashboard_common_table">
                <h3 style={{ fontSize: 16 }}>View Bookings (Total Pax : )</h3>
                <div className="table-responsive-lg table_common_area">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Booking ID</th>
                        <th>Destination</th>
                        <th>Departure Date</th>
                        <th>Booking amount</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookings?.map((booking) => {
                        return (
                          <tr>
                            <td>{booking?.reference_number ?? '-'}</td>
                            <td>
                              {booking?.onward_departure_airport?.code}
                              {' - '}
                              {booking.onward_arrival_airport?.code}
                            </td>
                            <td>{booking?.onward_departure_date}</td>
                            <td>
                              €
                              {booking?.total !== undefined
                                ? priceRound(booking?.total)
                                : 0}
                            </td>
                            <td
                              className="complete"
                              style={{
                                fontWeight: 'bold',
                                color:
                                  booking?.status === CONFIRMED
                                    ? GREEN
                                    : booking?.status === DELETED
                                    ? RED
                                    : ORANGE,
                              }}
                            >
                              {booking?.status === DELETED
                                ? CANCELLED_BY_ADMIN
                                : booking?.status}
                            </td>
                            <td>
                              <i
                                onClick={() => {
                                  onPressAction(booking?.reference_number);
                                }}
                                className="fas fa-eye"
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
