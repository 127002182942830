import { LIGHT_YELLOW, LIGHT_RED } from '../../../utils';

export const styles = {
  cardcontent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  countryTxt: {
    color: 'white',
    justifyContent: 'center',
    paddingBlock: 'auto',
    alignSelf: 'center',
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  countryTxtBox: {
    width: '95%',
    height: 45,
    alignItems: 'center',
    backgroundColor: '#212529',
    justifyContent: 'center',
    display: 'flex',
  },
  flexBox: {
    flex: 10.5,
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  arrow: { marginTop: 2, height: 35, width: 35 },
  baggageTxt: {
    textAlign: 'center',
    backgroundColor: '#D3E1FC',
    borderColor: '#BCD3FA',
    borderWidth: 2,
    fontWeight: '500',
  },
  dateCardBox: {
    maxWidth: 320,
    height: 55,
    marginBottom: 1,
    alignItems: 'center',
    backgroundColor: LIGHT_YELLOW,
    border: '2px solid #E08B32',
    borderRadius: 0,
    boxShadow: 'none',
    margin: 1,
  },
  resultCardBox: {
    maxWidth: 320,
    borderRadius: 0,
    boxShadow: 'none',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    '&:hover': {
      // cursor: 'not-allowed',
      cursor: 'pointer',
    },
    margin: 1,
  },
  resultCardTop: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#D6D6D6',
    paddingInline: 5,
  },
  fullBox: {
    backgroundColor: LIGHT_RED,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  seatsTxt: {
    textAlign: 'center',
    backgroundColor: '#F3D8DA',
    fontWeight: 'bold',
  },
  economyTxt: {
    textAlign: 'center',
    fontWeight: 'bold',
    paddingInline: 10,
  },
  directBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  seatBox: {
    marginBlock: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
