import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { WHITE, WEIGHT_23_2, WEIGHT_23_1, WEIGHT_30 } from '../../../utils';
import { getImageUrl } from '../../api/util';
import {
  LuggageOutlined,
  FlightClassOutlined,
  MoreVertRounded,
  FlightTakeoffRounded,
  AccessibilityNewRounded,
  BoyRounded,
  Man4Rounded,
} from '@mui/icons-material';
import { styles } from './styles';

const ResultCard = ({ flight, params, setFare, selectedFare }) => {
  const computeTotalPrice = (flightFare) => {
    const total =
      params.adultsCount * (flightFare?.adult_price || 0.0) +
      params.childCount * (flightFare?.child_price || 0.0) +
      params.infantsCount * (flightFare?.infant_price || 0.0);
    return total;
  };

  const showWeightImage = () => {
    return flight.seat_class.check_in_baggage_weight_allowance === '23Kg' &&
      flight.seat_class.check_in_baggage_count_allowance === '1'
      ? WEIGHT_23_1
      : flight.seat_class.check_in_baggage_weight_allowance === '23Kg' &&
        flight.seat_class.check_in_baggage_count_allowance === '2'
      ? WEIGHT_23_2
      : flight.seat_class.check_in_baggage_weight_allowance === '30Kg' &&
        flight.seat_class.check_in_baggage_count_allowance === '1'
      ? WEIGHT_30
      : null;
  };

  return (
    <>
      <Card
        onClick={() => {
          if (flight.number_of_seats_available !== 0) {
            setFare(flight);
          }
        }}
        className="styles.cardcontent"
        sx={[
          styles.resultCardBox,
          {
            '&:hover': {
              cursor:
                flight.number_of_seats_available == 0
                  ? 'not-allowed'
                  : 'pointer',
              border:
                flight.number_of_seats_available == 0
                  ? '1px solid #D6D6D6'
                  : '2px solid #000',
            },
            border: selectedFare === flight ? '2px solid #000' : null,
          },
        ]}
      >
        <CardContent
          sx={{ p: 0, '&:last-child': { pb: 0 } }}
          className={styles.cardcontent}
        >
          <div style={styles.resultCardTop}>
            <div>
              <Typography
                fontSize={24}
                sx={{ fontWeight: 'bold' }}
                variant="body1"
                color="black"
              >
                {flight?.seat_class.flight.departure_airport?.code}
              </Typography>
              <Typography
                sx={{ fontWeight: 'bold', textAlign: 'center' }}
                variant="body2"
                color="black"
              >
                {flight.departure_time}
              </Typography>
            </div>
            <div style={{ alignItems: 'center', alignSelf: 'center' }}>
              <div style={styles.directBox}>
                <FlightTakeoffRounded
                  sx={{ alignSelf: 'center' }}
                  color="inherit"
                  style={{ alignSelf: 'center' }}
                />
              </div>
              <Typography
                fontSize={14}
                sx={{ fontWeight: 'bold' }}
                variant="body1"
                color="black"
              >
                Direct
              </Typography>
            </div>
            <div>
              <Typography
                fontSize={24}
                sx={{ fontWeight: 'bold' }}
                variant="body1"
                color="black"
              >
                {flight?.seat_class.flight.arrival_airport?.code}
              </Typography>
              <Typography
                sx={{ fontWeight: 'bold', textAlign: 'center' }}
                variant="body2"
                color="black"
              >
                {flight.arrival_time}{' '}
                {flight.departure_date !== flight.arrival_date ? '(+1)' : null}
              </Typography>
            </div>
          </div>

          {flight.number_of_seats_available == 0 ? (
            <div style={styles.fullBox}>
              <Typography
                fontSize={12}
                sx={{
                  fontWeight: 'bold',
                }}
                variant="body2"
                color={WHITE}
              >
                FULL
              </Typography>
            </div>
          ) : null}

          <div style={styles.seatBox}>
            <div style={{ display: 'block', alignItems: 'center' }}>
              <FlightClassOutlined
                sx={{ height: 75, width: 75 }}
                color="inherit"
                style={{ alignSelf: 'center' }}
              />
              <Typography
                fontSize={10}
                style={styles.economyTxt}
                variant="body2"
                color="green"
              >
                {flight.seat_class.name}
              </Typography>
            </div>
            <div
              className="img"
              style={{
                alignSelf: 'center',
              }}
            >
              <img
                src={getImageUrl(flight.seat_class.flight.carrier.logo)}
                style={{
                  maxWidth: 110,
                  alignSelf: 'center',
                }}
                alt="img"
              />
            </div>
            <div>
              {showWeightImage() !== null ? (
                <img
                  src={showWeightImage()}
                  style={{
                    maxWidth: 75,
                    maxHeight: 75,
                  }}
                  alt="img"
                />
              ) : (
                <>
                  <LuggageOutlined
                    sx={{ height: 75, width: 75 }}
                    color="inherit"
                    style={{ alignSelf: 'center' }}
                  />
                  <Typography
                    fontSize={10}
                    style={styles.economyTxt}
                    variant="body2"
                    color="green"
                  >
                    {`${flight.seat_class.check_in_baggage_weight_allowance} X 
                    ${flight.seat_class.check_in_baggage_count_allowance}`}
                  </Typography>
                </>
              )}
            </div>
          </div>
          <div style={styles.directBox}>
            <Typography
              fontSize={30}
              style={{ textAlign: 'center', fontWeight: 'bold' }}
              variant="body2"
              color="#ED702D"
            >
              {computeTotalPrice(flight)} €
            </Typography>
            <Typography
              fontSize={18}
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                marginLeft: 7,
              }}
              variant="body2"
              color="black"
            >
              ({params.adultsCount} x
            </Typography>
            <BoyRounded sx={{ height: 20, width: 20 }} />
            {params.childCount !== 0 ? (
              <>
                <Typography
                  fontSize={18}
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                  variant="body2"
                  color="black"
                >
                  |
                </Typography>

                <Typography
                  fontSize={18}
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginLeft: 7,
                  }}
                  variant="body2"
                  color="black"
                >
                  {params.childCount} x
                </Typography>
                <AccessibilityNewRounded sx={{ height: 20, width: 20 }} />
              </>
            ) : null}
            {params.infantsCount !== 0 ? (
              <>
                <Typography
                  fontSize={18}
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginLeft: 7,
                  }}
                  variant="body2"
                  color="black"
                >
                  |
                </Typography>
                <Typography
                  fontSize={18}
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginLeft: 7,
                  }}
                  variant="body2"
                  color="black"
                >
                  {params.infantsCount} x
                </Typography>
                <Man4Rounded sx={{ height: 20, width: 20 }} />
              </>
            ) : null}
            <Typography
              fontSize={18}
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
              }}
              variant="body2"
              color="black"
            >
              )
            </Typography>
          </div>

          <div style={{}}>
            <Typography
              style={styles.baggageTxt}
              variant="body2"
              color={'#1C4193'}
            >
              {`${flight.seat_class.check_in_baggage_allowance}`},{' '}
              {flight.seat_class.cabin_baggage_allowance}
            </Typography>
            <Typography
              style={styles.seatsTxt}
              variant="body2"
              color={'#692326'}
            >
              {flight.number_of_seats_available ?? '-'} Seats Left
            </Typography>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ResultCard;
