import React, { useState } from 'react';
import { Button } from '@mui/material';
import {
  BLACK,
  ERROR,
  SOMETHING_WENT_WRONG,
  SUCCESS,
  showSnackbar,
} from '../../utils';
import Loading from './Loading/Loading';
import InputField from './InputField/InputField';
import { changePassword } from '../api/agent';

export default function MyProfile({ data }) {
  const [showErrorFields, setShowErrorFields] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formFields, setFormFields] = useState({
    oldPassword: '',
    newPassword: '',
  });

  const updateDetailsAction = async () => {
    setIsLoading(true);
    try {
      let obj = {
        current_password: formFields.oldPassword,
        new_password: formFields.newPassword,
      };
      const results = await changePassword(obj);
      if (results?.status === 'ok') {
        showSnackbar('Password updated successfully', SUCCESS);
      } else if (results?.status === 'error') {
        showSnackbar(results?.error ?? SOMETHING_WENT_WRONG, ERROR);
      }
    } catch (err) {
      if (err == 'sessionTimeout') {
      } else {
        showSnackbar(SOMETHING_WENT_WRONG, ERROR);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const canProceed = () => {
    return (
      formFields.oldPassword.length > 0 && formFields.newPassword.length > 0
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className="col-lg-8">
      <div className="dashboard_common_table">
        <h3>My Profile</h3>
        <div className="profile_update_form">
          <form id="profile_form_area">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label htmlFor="f-name">Name</label>
                  <span className="form-control">{data?.name ?? '-'}</span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="mail-address">Email address</label>
                  <span className="form-control">{data?.email ?? '-'}</span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="mobil-number">Mobile number</label>
                  <span className="form-control">
                    {data?.mobile_number ?? '-'}
                  </span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="mail-address">Country</label>
                  <span className="form-control">
                    {data?.agency?.address?.country ?? '-'}
                  </span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="mobil-number">City</label>
                  <span className="form-control">
                    {data?.agency?.address?.city ?? '-'}
                  </span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="mail-address">Address</label>
                  <span className="form-control">{data?.email ?? '-'}</span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="mobil-number">Pincode/Zipcode/Postcode</label>
                  <span className="form-control">
                    {data?.agency?.address?.zipcode ?? '-'}
                  </span>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label htmlFor="u-name">User name</label>
                  <span className="form-control">{data?.username ?? '-'}</span>
                </div>
              </div>
              {/* <div className="change_password_input_boxed">
                <h3>Change password</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <InputField
                      field={'oldPassword'}
                      formFields={formFields}
                      setFormFields={setFormFields}
                      type="password"
                      background="#FFF"
                      error={
                        showErrorFields
                          ? formFields.oldPassword.length === 0
                          : null
                      }
                      helperText={
                        showErrorFields && formFields.oldPassword.length === 0
                          ? 'It can not be blank'
                          : null
                      }
                      placeholder={'Old Password'}
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      field={'newPassword'}
                      formFields={formFields}
                      setFormFields={setFormFields}
                      type="password"
                      background="#FFF"
                      error={
                        showErrorFields
                          ? formFields.newPassword.length === 0
                          : null
                      }
                      helperText={
                        showErrorFields && formFields.newPassword.length === 0
                          ? 'It can not be blank'
                          : null
                      }
                      placeholder={'New Password'}
                    />
                  </div>
                </div>
              </div> */}
            </div>
            {/* <Button
              onClick={() => {
                if (canProceed()) {
                  updateDetailsAction();
                } else {
                  setShowErrorFields(true);
                }
              }}
              variant="contained"
              className="btn btn_theme btn_md"
              style={{
                backgroundColor: BLACK,
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Submit
            </Button> */}
          </form>
        </div>
      </div>
    </div>
  );
}
