import React, { useState, useEffect, useContext } from 'react';
import InputField from './InputField/InputField';
import CountriesList from '../constants/Countrues';
import format from 'date-fns/format';
import { BLACK } from '../../utils';

export default function PassengerForm({
  passengerNumber,
  showErrorFields,
  bookingContext,
  passengerId,
  arrivalDate,
}) {
  const { bookingState, modifyData } = useContext(bookingContext);

  const [formFields, setFormFields] = useState({
    title: 'Title',
    gender: 'Gender',
    firstName: '',
    lastName: '',
    contact: '',
    email: '',
    wheelChair: false,
    passportNo: '',
    nationality: 'Nationality',
    issueCountry: 'Issue Country',
    dob: null,
    passportIssue: null,
    passportExpiry: null,
  });

  const styles = {
    passengerCount: {
      fontSize: 20,
      backgroundColor: BLACK,
      color: 'white',
      paddingBlock: 5,
      paddingInline: 10,
      borderRadius: 5,
      fontWeight: '500',
    },
  };

  const isFormValid = () => {
    return (
      formFields?.firstName.length > 0 &&
      formFields?.lastName.length > 0 &&
      formFields?.contact.length > 0 &&
      formFields?.email.length > 0 &&
      formFields?.passportNo.length > 0 &&
      formFields.dob != null &&
      formFields.passportIssue != null &&
      formFields.passportExpiry != null &&
      formFields.issueCountry != 'Issue Country' &&
      formFields.nationality != 'Nationality' &&
      formFields.title != 'Title' &&
      formFields.gender != 'Gender'
    );
  };

  useEffect(() => {
    modifyData({
      passengers: {
        [passengerId]: {
          first_name: formFields.firstName,
          last_name: formFields.lastName,
          isValidated: isFormValid(),
          pax_type: bookingState?.passengers[passengerId]?.pax_type,
          contact_number: formFields?.contact,
          email: formFields?.email,
          passport_number: formFields?.passportNo,
          dob: formFields.dob,
          passport_issued_at: formFields.passportIssue,
          passport_expiring_at: formFields.passportExpiry,
          passport_issue_country: formFields.issueCountry,
          nationality: formFields.nationality,
          title: formFields.title,
          gender: formFields.gender,
          wheel_chair_required: formFields.wheelChair,
        },
      },
    });
  }, [isFormValid(), formFields]);

  return (
    <div
      id={'Passenger' - passengerNumber}
      className="tour_booking_form_box"
      style={{ marginBottom: 15 }}
    >
      <span style={styles.passengerCount}>{`Passenger ${
        passengerNumber + 1
      } - ${bookingState?.passengers[passengerId]?.pax_type}`}</span>
      <div id="tour_bookking_form_item">
        <div className="row" style={{ marginTop: 15 }}>
          <div className="col-lg-6">
            <div className="form-group">
              <select
                style={{
                  borderColor:
                    showErrorFields && formFields.title === 'Title'
                      ? '#d32f2f'
                      : null,
                }}
                className="form-control form-select bg_input"
                value={formFields.title}
                onChange={(e) => {
                  setFormFields((formFields) => ({
                    ...formFields,
                    title: e.target.value,
                  }));
                }}
              >
                <option value="Title">Title*</option>
                <option value="MR">MR</option>
                <option value="MRS">MRS</option>
              </select>
              <text style={{ fontSize: 12, marginLeft: 15, color: '#d32f2f' }}>
                {showErrorFields && formFields.title === 'Title'
                  ? 'Please select Title'
                  : null}
              </text>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <select
                style={{
                  borderColor:
                    showErrorFields && formFields.gender === 'Gender'
                      ? '#d32f2f'
                      : null,
                }}
                className="form-control form-select bg_input"
                value={formFields.gender}
                onChange={(e) => {
                  setFormFields((formFields) => ({
                    ...formFields,
                    gender: e.target.value,
                  }));
                }}
              >
                <option value="Gender">Gender*</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <text style={{ fontSize: 12, marginLeft: 15, color: '#d32f2f' }}>
                {showErrorFields && formFields.gender === 'Gender'
                  ? 'Please select Gender'
                  : null}
              </text>
            </div>
          </div>
          <div className="col-lg-6">
            <InputField
              field={'firstName'}
              formFields={formFields}
              setFormFields={setFormFields}
              error={
                showErrorFields ? formFields?.firstName?.length === 0 : null
              }
              helperText={
                showErrorFields && formFields?.firstName?.length === 0
                  ? 'First Name can not be blank'
                  : null
              }
              placeholder={'First Name*'}
            />
          </div>
          <div className="col-lg-6">
            <InputField
              field={'lastName'}
              formFields={formFields}
              setFormFields={setFormFields}
              error={
                showErrorFields ? formFields?.lastName?.length === 0 : null
              }
              helperText={
                showErrorFields && formFields?.lastName?.length === 0
                  ? 'Last Name can not be blank'
                  : null
              }
              placeholder={'Last Name*'}
            />
          </div>
          <div className="col-lg-6">
            <InputField
              type="number"
              field={'contact'}
              formFields={formFields}
              setFormFields={setFormFields}
              error={showErrorFields ? formFields?.contact?.length === 0 : null}
              helperText={
                showErrorFields && formFields?.contact?.length === 0
                  ? 'Contact number can not be blank'
                  : null
              }
              placeholder={'Contact Number*'}
            />
          </div>

          <div className="col-lg-6">
            <InputField
              type="email"
              field={'email'}
              formFields={formFields}
              setFormFields={setFormFields}
              error={showErrorFields ? formFields?.email?.length === 0 : null}
              helperText={
                showErrorFields && formFields?.email?.length === 0
                  ? 'Email can not be blank'
                  : null
              }
              placeholder={'Email*'}
            />
          </div>

          <div className="col-lg-6">
            <label className="form-check-label" for="flexRadioDefault3">
              Date of Birth
            </label>
            <input
              type="date"
              max={format(new Date(), 'yyyy-MM-dd')}
              onChange={(e) => {
                setFormFields((formFields) => ({
                  ...formFields,
                  dob: e.target.value,
                }));
              }}
              className="form-control bg_input"
              placeholder="Date of Birth"
            />
          </div>

          <div className="col-lg-6">
            <label className="form-check-label" for="flexRadioDefault3"></label>
            <div className="form-group">
              <select
                style={{
                  borderColor:
                    showErrorFields && formFields.nationality === 'Nationality'
                      ? '#d32f2f'
                      : null,
                }}
                className="form-control form-select bg_input"
                value={formFields.nationality}
                onChange={(e) => {
                  setFormFields((formFields) => ({
                    ...formFields,
                    nationality: e.target.value,
                  }));
                }}
              >
                <option value="Nationality">Nationality*</option>
                {CountriesList.map((country) => {
                  return <option value={country}>{country}</option>;
                })}
              </select>
              <text style={{ fontSize: 12, marginLeft: 15, color: '#d32f2f' }}>
                {showErrorFields && formFields.nationality === 'Nationality'
                  ? 'Please select Nationality'
                  : null}
              </text>
            </div>
          </div>

          <div
            className="tour_details_right_box_heading"
            style={{ marginBlock: 25 }}
          >
            <h3 style={{ fontSize: 22 }}>Passport details</h3>
          </div>

          <div className="col-lg-6">
            <InputField
              field={'passportNo'}
              formFields={formFields}
              setFormFields={setFormFields}
              error={
                showErrorFields ? formFields?.passportNo?.length === 0 : null
              }
              helperText={
                showErrorFields && formFields?.passportNo?.length === 0
                  ? 'Passport number can not be blank'
                  : null
              }
              placeholder={'Passport number*'}
            />
          </div>
          <div className="col-lg-6">
            <div className="form-group" style={{ marginTop: 10 }}>
              <select
                style={{
                  borderColor:
                    showErrorFields &&
                    formFields.issueCountry === 'Issue Country'
                      ? '#d32f2f'
                      : null,
                }}
                className="form-control form-select bg_input"
                value={formFields.issueCountry}
                onChange={(e) => {
                  setFormFields((formFields) => ({
                    ...formFields,
                    issueCountry: e.target.value,
                  }));
                }}
              >
                <option value="Issue Country">Issue Country*</option>
                {CountriesList.map((country) => {
                  return <option value={country}>{country}</option>;
                })}
              </select>
              <text style={{ fontSize: 12, marginLeft: 15, color: '#d32f2f' }}>
                {showErrorFields && formFields.issueCountry === 'Issue Country'
                  ? 'Please select Issue Country'
                  : null}
              </text>
            </div>
          </div>
          <div className="col-lg-6">
            <label className="form-check-label" for="flexRadioDefault3">
              Passport Issue
            </label>
            <input
              type="date"
              min={format(new Date(formFields.dob), 'yyyy-MM-dd')}
              max={format(new Date(), 'yyyy-MM-dd')}
              onChange={(e) => {
                setFormFields((formFields) => ({
                  ...formFields,
                  passportIssue: e.target.value,
                }));
              }}
              className="form-control bg_input"
              placeholder="Passport issue"
            />
          </div>

          <div className="col-lg-6">
            <label className="form-check-label" for="flexRadioDefault3">
              Passport Expiry
            </label>
            <input
              type="date"
              min={format(new Date(arrivalDate), 'yyyy-MM-dd')}
              onChange={(e) => {
                setFormFields((formFields) => ({
                  ...formFields,
                  passportExpiry: e.target.value,
                }));
              }}
              className="form-control bg_input"
              placeholder="Passport expiry"
            />
          </div>

          <div
            className="col-lg-12"
            style={{ marginTop: 30, marginBottom: 10 }}
          >
            <div className="booking_tour_form_submit">
              <div className="form-check write_spical_check">
                <input
                  onClick={() => {
                    setFormFields((formFields) => ({
                      ...formFields,
                      wheelChair: !formFields.wheelChair,
                    }));
                  }}
                  className="form-check-input"
                  type="checkbox"
                  value={formFields.wheelChair}
                  id="flexCheckDefaultf1"
                />
                <label className="form-check-label" for="flexCheckDefaultf1">
                  Add Wheel Chair
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
